import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Box, Card, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

import { organizationQueryKeyAtom } from './RestaurantForm.atoms';
import { IndexOrganizations } from '@restworld/data-services/src/lib/services/useOrganizations/types';

export default function OrganizationQueryListUI({ isLoading }: { isLoading: boolean }) {
  const queryClient = useQueryClient();
  const queryKey = useAtomValue(organizationQueryKeyAtom);
  const theme = useTheme();
  const navigate = useNavigate();

  const organizationList = queryClient.getQueryData(queryKey) as IndexOrganizations['data'];

  const onChangeOrganization = React.useCallback(
    (organizationId: string) => {
      navigate(
        `/registration-forms/restaurant?mode=create&organizationId=${organizationId}&activeCategoryId=local_information`
      );
    },
    [navigate]
  );

  return (
    <List sx={{ width: '100%' }}>
      {!!organizationList?.length &&
        organizationList?.map(({ organization }, i) => (
          <ListItem
            sx={{
              '&:hover': {
                bgcolor: 'grey.100',
                cursor: 'pointer'
              },
              p: 3,
              mb: 3,
              boxShadow: theme.shadows[5]
            }}
            components={{ Root: Card }}
            key={i}
            onClick={() => {
              onChangeOrganization(organization.id);
            }}
          >
            <OrganizationItem organizationName={organization.name} />
          </ListItem>
        ))}
      {isLoading ? (
        <em>Caricamento...</em>
      ) : (
        !organizationList?.length && <em>Nessun employer corrispondente alla ricerca!</em>
      )}
    </List>
  );
}
const OrganizationItem = ({ organizationName }: { organizationName: string }) => (
  <Box>
    <Typography variant="body1" fontWeight="900">
      {organizationName}
    </Typography>
  </Box>
);
