import { ReactNode, useContext } from 'react';
import { SidebarContext } from 'contexts/SidebarContext';
import { Box } from '@mui/material';
import { SIDEBAR_TRANSITION_DURATION } from 'config';
import { useTheme } from '@mui/material';

export default function SidebarMainContainer({ children }: { children: ReactNode | ReactNode[] }) {
  const theme = useTheme();
  const { sidebarStatus } = useContext(SidebarContext);
  return (
    <Box
      sx={{
        marginLeft: sidebarStatus.left.width,
        marginRight: sidebarStatus.right.width,
        transition: theme.transitions.create(['margin'], { duration: SIDEBAR_TRANSITION_DURATION })
      }}
    >
      {children}
    </Box>
  );
}
