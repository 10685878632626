import { Container, Grid } from '@mui/material';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import useCvParsingFeedback from './hooks/use-cv-parsing-feedback';
import ShowOriginalCv from './components/showOriginalCv';
import CollectFeedback from './components/collectFeedback';
import useSocketPresence from './hooks/use-socket-presence';
import { useEffect } from 'react';
import Navbar from './components/Navbar';

export default function CvParsingFeedback() {
  const { themeStretch } = useSettings();
  const {
    parsedCvInfo,
    saveCorrections,
    skipCv,
    isSkippingLoading,
    isSavingLoading,
    correctedCvCount
  } = useCvParsingFeedback();
  const { initWorker } = useSocketPresence();

  useEffect(() => {
    if (!parsedCvInfo?.worker_id) return;
    initWorker(parsedCvInfo.worker_id);
  }, [parsedCvInfo, initWorker]);

  return (
    <Page title="CV Parsing feedback">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          <Grid item xs={12}>
            {!!parsedCvInfo?.cv_url && (
              <Navbar
                cvUrl={parsedCvInfo.cv_url}
                handleSkipCv={skipCv}
                isSkippingLoading={isSkippingLoading}
                correctedCvCount={correctedCvCount}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {/* Original CV */}
            {!!parsedCvInfo?.cv_url && <ShowOriginalCv cvUrl={parsedCvInfo.cv_url} />}
          </Grid>
          <Grid item xs={12} lg={6}>
            {/* Parsed information */}
            {!!parsedCvInfo && (
              <CollectFeedback
                parsedCvInfo={parsedCvInfo}
                saveCorrections={saveCorrections}
                isSavingLoading={isSavingLoading}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
