export const UserTypes = {
  rw_remember: 'rw_member'
};

export const UserRoles = {
  tam: 'tam',
  csm: 'csm',
  jr: 'junior_recruiter'
};

export const OrderAndPaginationDefaultValue = {
  order: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 25,
  searchValue: ''
};

export const timeoutOnSuccess = 1000;
export const timeoutOnError = 1000;

export const activeJobApplicationStatus = [
  'worker_jp_in_shortlist',
  'worker_jp_interview_scheduled',
  'worker_jp_stage_scheduled',
  'worker_jp_stage',
  'worker_jp_hired'
];

export const inactiveJobApplicationStatus = [
  'worker_jp_not_suitable',
  'worker_jp_not_interviewed',
  'worker_jp_interview_failed',
  'worker_jp_stage_failed',
  'worker_jp_refused',
  'worker_jp_discarded',
  'worker_jp_fired'
];

export const jpActiveStatuses = [
  'jp_approval',
  'jp_posting',
  'jp_screening',
  'jp_shortlist_formation',
  'jp_shortlist_sent'
];

export const intercomContactLink = (intercom_contact_id: string | undefined) => {
  return 'https://app.intercom.com/a/apps/ltjg1h8s/users/' + intercom_contact_id;
};
