import { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { Add, AutoFixHighOutlined, Close, MoreVert } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { Drawer } from '@mui/material';
import {
  JP_TEXT_GENERATION_CHAT_POSITION,
  JP_TEXT_GENERATION_CHAT_WIDTH,
  SIDEBAR_TRANSITION_DURATION
} from 'config';
import { SidebarContext } from 'contexts/SidebarContext';
import { CategoryPrompts, GenerationStatus, Prompt } from 'hooks/useJobPositionTextGeneration';
import ChatItem from './ChatItem';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import { PromptItem } from '@restworld/data-services';
import { EntityId } from '@restworld/utility-types';
import ChatItemActionMenu from './ChatItemActionMenu';
import GenerationChatActionMenu from './GenerationChatActionMenu';

interface Props {
  category: keyof CategoryPrompts;
  prompt?: Prompt;
  chatCompletion: (category: keyof CategoryPrompts, inputPrompt: Array<PromptItem>) => void;
  textGenerationStatus: GenerationStatus;
  jobPositionId?: EntityId;
  handlePropagateMessage: (content: string) => void;
  fetchPrompt: (category: keyof CategoryPrompts) => void;
  handlePromptReset: () => void;
}

const processInputPrompt = (prompt?: Prompt) => {
  if (!prompt) return null;
  return prompt.map((item, index) => ({ ...item, id: index }));
};

export function SidebarGenerationChat({
  category,
  prompt,
  chatCompletion,
  textGenerationStatus,
  jobPositionId,
  handlePropagateMessage,
  handlePromptReset,
  fetchPrompt
}: Props) {
  const theme = useTheme();
  const { user } = useAuth();
  const { sidebarStatus, handleToggle } = useContext(SidebarContext);

  const [internalPrompt, setInternalPrompt] = useState<Array<
    PromptItem & { isEditing?: boolean; id: number }
  > | null>(processInputPrompt(prompt));

  useEffect(() => {
    if ((!prompt || prompt.length === 0) && !!category) {
      fetchPrompt(category);
    }
    !!prompt && setInternalPrompt(processInputPrompt(prompt));
  }, [prompt, category, fetchPrompt]);

  const isGenerationChatOpen =
    sidebarStatus[JP_TEXT_GENERATION_CHAT_POSITION].sidebarName === 'jp_generation_chat' &&
    sidebarStatus[JP_TEXT_GENERATION_CHAT_POSITION].isOpen;

  const handleToggleSelf = useCallback(() => {
    handleToggle(
      'jp_generation_chat',
      JP_TEXT_GENERATION_CHAT_POSITION,
      JP_TEXT_GENERATION_CHAT_WIDTH
    );
  }, [handleToggle]);

  const handleChatCompletion = useCallback(
    (prompt: Prompt) => {
      if (!category) return;
      chatCompletion(
        category,
        prompt.map((item) => ({ ...item, isEditing: undefined, id: undefined }))
      );
    },
    [chatCompletion, category]
  );

  const handleAddMessage = useCallback(() => {
    setInternalPrompt((prev) => {
      const index = prev?.length || 0;
      return [...(prev || []), { role: 'user', content: '', isEditing: true, id: index }];
    });
  }, []);

  const handleSaveMessage = useCallback((id: number, content: string) => {
    setInternalPrompt((prev) => {
      if (!prev) return null;
      if (content !== '')
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, content, isEditing: false };
          }
          return item;
        });
      return prev.filter((item) => item.id !== id);
    });
  }, []);

  // Overall chat action menu logic
  const [chatMenuAnchorEl, setChatMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseChatMenu = useCallback(() => {
    setChatMenuAnchorEl(null);
  }, []);

  const handleOpenChatMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setChatMenuAnchorEl(event.currentTarget);
  }, []);

  // Chat item action menu logic
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedChatItemId, setSelectedChatItemId] = useState<number | null>(null);

  const handleCloseActionMenu = useCallback(() => {
    setActionMenuAnchorEl(null);
    setSelectedChatItemId(null);
  }, []);

  const handleDeleteMessage = useCallback(() => {
    setInternalPrompt((prev) => {
      if (!prev) return null;
      return prev.filter((item) => item.id !== selectedChatItemId);
    });
    handleCloseActionMenu();
  }, [handleCloseActionMenu, selectedChatItemId]);

  const handleEditMessage = useCallback(() => {
    setInternalPrompt((prev) => {
      if (!prev) return null;
      return prev.map((item) => {
        if (item.id === selectedChatItemId) {
          return { ...item, isEditing: true };
        }
        return item;
      });
    });
    handleCloseActionMenu();
  }, [handleCloseActionMenu, selectedChatItemId]);

  const handleCopyMessage = useCallback(() => {
    const content = internalPrompt?.find((item) => item.id === selectedChatItemId)?.content;
    if (content) handlePropagateMessage(content);
    handleCloseActionMenu();
  }, [handleCloseActionMenu, handlePropagateMessage, internalPrompt, selectedChatItemId]);

  const handleOpenActionMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
      setActionMenuAnchorEl(event.currentTarget);
      setSelectedChatItemId(id);
    },
    []
  );

  const sidebarWidth = useMemo(() => {
    if (!actionMenuAnchorEl && !chatMenuAnchorEl) return JP_TEXT_GENERATION_CHAT_WIDTH;
    return `calc(${JP_TEXT_GENERATION_CHAT_WIDTH} + 15px)`;
  }, [actionMenuAnchorEl, chatMenuAnchorEl]);

  // Logic to control the display of the fixed navbar
  const [showHeader, setShowHeader] = useState<boolean>(false);
  useEffect(() => {
    if (isGenerationChatOpen) {
      setTimeout(() => setShowHeader(true), SIDEBAR_TRANSITION_DURATION);
    } else {
      setShowHeader(false);
    }
  }, [isGenerationChatOpen]);

  return (
    <Drawer
      open={isGenerationChatOpen ? true : showHeader}
      variant="persistent"
      anchor={JP_TEXT_GENERATION_CHAT_POSITION}
      PaperProps={{
        sx: {
          minWidth: sidebarWidth,
          maxWidth: sidebarWidth,
          pr: !!actionMenuAnchorEl || !!chatMenuAnchorEl ? '15px' : '0'
        }
      }}
    >
      <GenerationChatActionMenu
        anchorEl={chatMenuAnchorEl}
        handleClose={handleCloseChatMenu}
        handlePromptReset={() => {
          handlePromptReset();
          handleCloseChatMenu();
        }}
        category={category}
      />
      <ChatItemActionMenu
        anchorEl={actionMenuAnchorEl}
        role={internalPrompt?.find((item) => item.id === selectedChatItemId)?.role}
        handleClose={handleCloseActionMenu}
        handleDeleteMessage={handleDeleteMessage}
        handleEditMessage={handleEditMessage}
        handleCopyMessage={handleCopyMessage}
      />
      <Box
        sx={{
          position: showHeader ? 'fixed' : 'relative',
          width: showHeader ? sidebarWidth : 'auto',
          bgcolor: 'white',
          zIndex: 1200,
          pt: 3,
          pr: !!actionMenuAnchorEl || !!chatMenuAnchorEl ? '15px' : '0'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: theme.spacing(3),
            mb: theme.spacing(2)
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
            <IconButton onClick={handleToggleSelf}>
              <Close />
            </IconButton>
            <Typography variant="h6">{getCategoryLabel(category)}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            <Tooltip
              title="Hai un messaggio non salvato, puoi eliminarlo oppure salvarlo"
              disableHoverListener={internalPrompt?.every((item) => !item.isEditing)}
            >
              <Box>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  startIcon={<AutoFixHighOutlined />}
                  onClick={() => !!internalPrompt && handleChatCompletion(internalPrompt)}
                  loading={!!category && textGenerationStatus[category] === 'LOADING'}
                  disabled={internalPrompt?.some((item) => item.isEditing)}
                >
                  Genera
                </LoadingButton>
              </Box>
            </Tooltip>
            <IconButton onClick={handleOpenChatMenu}>
              <MoreVert />
            </IconButton>
          </Stack>
        </Box>
        <Divider />
      </Box>
      <Box sx={{ p: 3, mt: showHeader ? 10 : 0 }}>
        {!!internalPrompt && (
          <Stack spacing={1}>
            {internalPrompt.map((item, index) => (
              <ChatItem
                key={index}
                avatarUrl={
                  item.role === 'user' || item.role === 'system'
                    ? user?.data?.icon_url
                    : 'https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/common_icons%2Frobot.png'
                }
                content={item.content}
                isEditing={item.isEditing || false}
                handleSaveMessage={(message) => handleSaveMessage(item.id, message)}
                handleOpenActionMenu={(e) => handleOpenActionMenu(e, item.id)}
              />
            ))}
          </Stack>
        )}
        <Stack direction="row" justifyContent={'center'} sx={{ mt: 4 }}>
          <Button color="primary" variant="outlined" startIcon={<Add />} onClick={handleAddMessage}>
            Aggiungi messaggio
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}

const getCategoryLabel = (category: keyof CategoryPrompts) => {
  switch (category) {
    case 'job_title':
      return "Titolo dell'annuncio";
    case 'job_description':
      return "Descrizione dell'annuncio";
    case 'job_contract_description':
      return 'Descrizione del contratto';
    case 'csm_insights':
      return 'Il CSM consiglia';
    default:
      return 'N.D.';
  }
};
