import { IRestaurantFormCategory } from './RestaurantForm.types';

export const restaurantFormCategories: IRestaurantFormCategory[] = [
  {
    label: 'Informazioni locale',
    id: 'local_information',
    fields: [
      {
        label: 'Come si chiama il locale',
        id: 'restaurant_name'
      },
      {
        label: 'Dove si trova il locale?',
        id: 'address'
      },
      {
        label: 'Città',
        id: 'locality'
      },
      {
        label: 'Regione',
        id: 'administrative_area_level_1'
      },
      {
        label: 'Codice postale',
        id: 'postal_code'
      },
      {
        label: 'Chi decide quali persone assumere?',
        id: 'management_type',
        select_type: 'single'
      }
    ]
  },
  {
    label: 'Dettagli del Ristorante',
    id: 'restaurant_details',
    fields: [
      {
        label: 'Che tipologia di locale è?',
        id: 'types',
        variable_name: 'restaurant_types',
        select_type: 'multiple'
      },
      {
        label: 'Specificare se altro',
        id: 'free_text_restaurant_type'
      },
      {
        label: 'Note specifiche sulla cucina del locale',
        id: 'cuisine_note'
      },
      {
        label: 'Tipologia di ambiente',
        id: 'environment_type',
        variable_name: 'environment_type',
        select_type: 'single'
      },
      {
        label: 'Che tipologia di clienti avete?',
        id: 'client_types',
        variable_name: 'client_type',
        select_type: 'multiple'
      },
      {
        label: 'Lo staff è distinguibile?',
        id: 'is_staff_distinguishable'
      },
      {
        label: 'Quante persone lavorano in sala? (Compreso banco, bar e cassa)',
        id: 'staff_size_floor'
      },
      {
        label: 'Quante persone lavorano in cucina?',
        id: 'staff_size_kitchen'
      }
    ]
  },
  // {
  //   label: 'Dettagli di consegna',
  //   id: 'delivery_details',
  //   fields: [
  //     {
  //       label: 'Quanti coperti o consegne fate a servizio?',
  //       id: 'covers_per_service',
  //       variable_name: 'covers_per_service',
  //       select_type: 'single'
  //     },
  //     {
  //       label: 'Qual è il prezzo medio a coperto o consegna?',
  //       id: 'covers_avg_price',
  //       variable_name: 'covers_avg_price',
  //       select_type: 'single'
  //     },
  //     {
  //       label: 'Giorni di chiusura',
  //       id: 'closing_days',
  //       select_type: 'multiple'
  //     },
  //     {
  //       label: 'Che turni fate?',
  //       id: 'shifts',
  //       select_type: 'multiple'
  //     }
  //   ]
  // },
  {
    label: 'Ultimi step',
    id: 'refer_details',
    fields: [
      {
        label: 'Che prospettive hai per il futuro prossimo del tuo locale?',
        id: 'future_goal',
        variable_name: 'future_goal',
        select_type: 'single'
      },
      {
        label: 'Seleziona la persona di riferimento per questo locale',
        id: 'referent_contact',
        select_type: 'multiple'
      }
    ]
  }
];

export const defaultPayloadFromData = {
  client_types: [],
  closing_days: [],
  shifts: [],
  types: [],
  referent_user_contacts: [],
  is_staff_distinguishable: true,
  cuisine_note: '',
  free_text_restaurant_type: '',
  restaurant_name: '',
  management_type: '',
  environment_type: '',
  covers_per_service: '',
  covers_avg_price: '',
  future_goal: '',
  address: '',
  locality: '',
  postal_code: '',
  business_address: ''
};
