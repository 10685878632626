import { styled } from '@mui/material/styles';
import MaterialTab from '@mui/material/Tab';
import MaterialTabs from '@mui/material/Tabs';

/**
 * 1. Common
 * 3. Tabs
 */

// 1. [ Common ]
export const styles = {
  sidebar: {
    border: '1px solid #E0E0E0',
    alignItems: 'center'
  },
  itemsCenter: {
    alignItems: 'center'
  }
};

export const ContentRight = styled('span')(() => ({
  textAlign: 'right',
  width: '100%',
  display: 'block'
}));

export const Spacer = styled('div')`
  height: ${(props: { size?: string }) => {
    switch (props.size) {
      case 'sm': {
        return '20px';
      }
      case 'md': {
        return '50px';
      }
      case 'lg': {
        return '80px';
      }
      default: {
        return '50px';
      }
    }
  }};
`;

export const Centered = styled('div')`
  text-align: center;
`;

export const LoaderBackground = styled('div')`
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
`;

export const Loader = styled('div')`
  position: absolute;
  inset: 0;
  margin: auto;
  height: 40px;
  width: 40px;
`;
export const AttachedTopRight = styled('div')`
  top: 0;
  right: 0;
  position: absolute;
`;
// [/ Common ]

// 3. [ Tabs ]
export const Tab = styled(MaterialTab)(
  ({ theme, active }: any) => `
  padding: 0px 1.25rem !important;
  min-height: 36px;
  min-width: 125px;
  border: 1px solid ${theme.palette.grey[600]};
  min-width: 150px;
  ${
    (active &&
      `
      background-color: ${theme.palette.grey[600]};
      color: #fff !important;
    `) ||
    ''
  }
  &:not(:last-of-type) {
    margin: 0px !important;
    border-top-right-radius: 0px;
  }
  &:not(:first-of-type) {
    border-top-left-radius: 0px;
  }
  svg {
    font-size: 16px;
  }
`
);

export const Tabs = styled(MaterialTabs)`
  &.MuiTabs-root {
    min-height: 36px !important;
  }
  .MuiTabs-indicator {
    height: 0px;
  }
`;

export const TabPanel = styled('div')(
  ({ theme }) => `
  position: relative;
  box-shadow: ${theme.customShadows.z16}
`
);
// [/ Tabs ]
