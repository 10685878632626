import { Grid, Link, Typography } from '@mui/material';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import SidebarFilters from 'components/SidebarFilters';
import { RWMemberUpdatePayloadType } from 'service/rw_members';
import { ContentRight, Spacer } from 'styles/common';

import TeamMateTableRolesFilter from './TeamMateTableRolesFilter';
import { useAtom } from 'jotai';
import {
  filtersAtom,
  teamMatesTablePageNumberAtom,
  teamMatesTablePaginationAtom
} from './TeamMatesTable';
import { SidebarContext } from 'contexts/SidebarContext';
import { SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH } from 'config';

interface ITeamMatesSideBarFilterDrawerProps {}
export type FilterFields = { roles?: RWMemberUpdatePayloadType['roles'] };
const TeamMatesSideBarFilterDrawer: React.FunctionComponent<ITeamMatesSideBarFilterDrawerProps> = (
  props
) => {
  const methods = useForm<FilterFields>({
    defaultValues: {
      roles: []
    },
    mode: 'onTouched'
  });
  const [, setFilters] = useAtom(filtersAtom);
  const [, setPagination] = useAtom(teamMatesTablePaginationAtom);
  const { handleSubmit, reset } = methods;
  const { handleToggle } = React.useContext(SidebarContext);
  const [, setPage] = useAtom(teamMatesTablePageNumberAtom);

  const onSubmit = React.useCallback(
    (values: FilterFields) => {
      setPagination({});
      setPage(0);
      setFilters(values);
      handleToggle('filter', SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH);
    },
    [handleToggle, setFilters, setPage, setPagination]
  );

  React.useEffect(() => {
    reset({
      roles: []
    });
  }, [reset]);

  return (
    <SidebarFilters>
      <FormProvider {...methods}>
        <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h5">Filtri</Typography>
            </Grid>
            <Grid item xs={8}>
              <ContentRight>
                <Link
                  onClick={() => {
                    setPagination({
                      limit: 7,
                      offset: 0
                    });
                    reset({
                      roles: []
                    });
                    setPage(0);
                    setFilters({});
                    handleToggle('filter', SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  Ripristina filtri
                </Link>
              </ContentRight>
            </Grid>
          </Grid>
          <Spacer size="sm" />
          <Grid sx={{ width: '100%' }} container>
            <TeamMateTableRolesFilter />
            <ContentRight
              sx={{
                marginTop: '1rem'
              }}
            >
              <LoadingButton type="submit" variant="contained" color="primary">
                Invia
              </LoadingButton>
            </ContentRight>
          </Grid>
        </form>
      </FormProvider>
    </SidebarFilters>
  );
};

export default TeamMatesSideBarFilterDrawer;
