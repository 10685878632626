import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { RWMemberRole } from '@restworld/utility-types';
import { Box, Chip, OutlinedInput } from '@mui/material';

import { FilterFields } from './TeamMatesSideBarFilterDrawer';
import { AVAILABLE_ROLES } from './TeamMates';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
export default function TeamMateTableRolesFilter() {
  const queryClient = useQueryClient();
  const availableRoles = queryClient.getQueryData<RWMemberRole[]>([AVAILABLE_ROLES]);

  const {
    control,
    formState: { errors }
  } = useFormContext<FilterFields>();
  return (
    <Controller
      name="roles"
      control={control}
      render={({ field }) => {
        return (
          <FormControl
            fullWidth
            sx={{
              mt: 4
            }}
            error={!!errors.roles}
          >
            <InputLabel id="roles">Ruoli</InputLabel>
            <Select
              labelId="roles"
              id="roles"
              multiple
              MenuProps={MenuProps}
              input={<OutlinedInput label="Ruolo" />}
              renderValue={() => {
                const selectedLabels = availableRoles
                  ?.filter((v) => (field.value as string[]).includes(v.key))
                  .map((v) => v.label);
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedLabels?.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                );
              }}
              {...field}
              onChange={(event) => {
                const {
                  target: { value }
                } = event;
                field.onChange(typeof value === 'string' ? value.split(',') : value);
              }}
            >
              {availableRoles?.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {!!errors.roles && <FormHelperText>{errors.roles.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}
