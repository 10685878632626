import {
  Drawer as BaseDrawer,
  DrawerProps as BaseDrawerProps,
  alpha,
  useTheme
} from '@mui/material';
import { SidebarContext } from 'contexts/SidebarContext';
import { useContext, useEffect } from 'react';

export default function Drawer({ shadow, ...props }: { shadow?: boolean } & BaseDrawerProps) {
  const theme = useTheme();
  const { setIsFABHidden } = useContext(SidebarContext);

  const { variant, open } = props;

  useEffect(() => {
    if (variant === 'temporary' && open) {
      setIsFABHidden(true);
    } else {
      setIsFABHidden(false);
    }

    return () => setIsFABHidden(false);
  }, [variant, open, setIsFABHidden]);

  const isLight = theme.palette.mode === 'light';
  return (
    <BaseDrawer
      {...props}
      sx={
        shadow
          ? {
              '& .MuiDrawer-paperAnchorLeft': {
                boxShadow: `8px 24px 24px 12px ${alpha(
                  theme.palette.grey[900],
                  isLight ? 0.16 : 0.48
                )}`
              },
              '& .MuiDrawer-paperAnchorRight': {
                boxShadow: `-8px 24px 24px 12px ${alpha(
                  theme.palette.grey[900],
                  isLight ? 0.16 : 0.48
                )}`
              }
            }
          : {}
      }
    >
      {props.children}
    </BaseDrawer>
  );
}
