import { Box, DialogContent, Grid, Typography } from '@mui/material';
import useAlgoliaSearch from 'hooks/useAlgoliaSearch';
import { Configure, Index, useSearchBox } from 'react-instantsearch-hooks';
import { MAIN_PATHS } from 'routes/paths';
import AlgoliaSearchBox from './AlgoliaSearchBox';
import EmployerHit from './EmployerHit';
import Hits from './Hits';
import JobPositionHit from './JobPositionHit';
import RestaurantHit from './RestaurantHit';
import WorkerHit from './WorkerHit';
import Dialog from 'components/Dialog';
import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import OrganizationHit from './OrganizationHit';

export default function AlgoliaSearch() {
  const { isOpen, handleToggle } = useAlgoliaSearch();
  const { userRoleKeys } = useAuth();
  const { query } = useSearchBox();

  const isOutsourcingSalesUser = useMemo(
    () => userRoleKeys?.includes('outsourcing_sales'),
    [userRoleKeys]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      PaperProps={{ sx: { minWidth: '70%', height: '90vh' } }}
    >
      {/* <DialogTitle>Cerca</DialogTitle> */}
      <DialogContent sx={{ mt: 2 }}>
        <Configure
          hitsPerPage={10}
          filters="NOT status_key:jp_trash AND NOT status_key:jp_archived AND NOT status_key:jp_suspended AND NOT status_key:jp_closed_lost AND NOT status_key:jp_closed_win"
        />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <AlgoliaSearchBox sx={{ width: '30%' }} />
        </Box>
        <Grid container spacing={2} sx={{ mt: 1, mx: 1 }}>
          <Grid item xs={12} md={6} lg={3}>
            {query !== '' && (
              <Index indexName="organizations">
                <Typography variant="h6">Employers</Typography>
                <Hits
                  hitComponent={OrganizationHit}
                  redirectTo={
                    // isOutsourcingSalesUser
                    //   ? `${MAIN_PATHS.registrationForms}/employer/start-subscription`
                    //   : MAIN_PATHS.organizations
                    MAIN_PATHS.organizations
                  }
                />
              </Index>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {query !== '' && !isOutsourcingSalesUser && (
              <Index indexName="restaurants">
                <Typography variant="h6">Locali</Typography>
                <Hits hitComponent={RestaurantHit} redirectTo={MAIN_PATHS.restaurants} />
              </Index>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {query !== '' && !isOutsourcingSalesUser && (
              <Index indexName="job_positions">
                <Typography variant="h6">Posizioni</Typography>
                <Hits hitComponent={JobPositionHit} redirectTo={MAIN_PATHS.jobPositions} />
              </Index>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {query !== '' && !isOutsourcingSalesUser && (
              <Index indexName="workers">
                <Typography variant="h6">Worker</Typography>
                <Hits hitComponent={WorkerHit} redirectTo={MAIN_PATHS.workers} />
              </Index>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
