import { Box, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CollectParsedCvErrors, UnanalysedParsedCv } from '@restworld/data-services';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { emptyReset, getPayloadDiff, processPhoneNumbers } from './util';
import { LoadingButton } from '@mui/lab';
import PastExperiences from './PastExperiences';

type Props = {
  parsedCvInfo: UnanalysedParsedCv;
  saveCorrections: (data: CollectParsedCvErrors) => void;
  isSavingLoading: boolean;
};

export type FormValues = UnanalysedParsedCv['parsed_info'] & {
  phone_number: string;
} & UnanalysedParsedCv['parsed_info']['location'];

export default function CollectFeedback({
  parsedCvInfo: inputParsedCv,
  saveCorrections,
  isSavingLoading
}: Props) {
  const parsedCvInfo = useMemo(() => inputParsedCv.parsed_info, [inputParsedCv]);

  const processFormData = useCallback((parsedCvInfo: UnanalysedParsedCv['parsed_info']) => {
    return {
      ...parsedCvInfo,
      phone_number: processPhoneNumbers(parsedCvInfo),
      past_experiences: parsedCvInfo?.past_experiences ?? [],
      ...(parsedCvInfo?.location ?? {})
    };
  }, []);

  const methods = useForm<FormValues>({
    defaultValues: processFormData(parsedCvInfo)
  });

  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    reset({ ...emptyReset, ...processFormData(parsedCvInfo) });
  }, [parsedCvInfo, processFormData, reset]);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      if (!data) return;
      const diffPayload = getPayloadDiff(parsedCvInfo, data);
      saveCorrections({
        errors: diffPayload,
        cv_parsed_url: inputParsedCv.parsed_cv_url,
        cv_url: inputParsedCv.cv_url
      });
    },
    [inputParsedCv, saveCorrections, parsedCvInfo]
  );

  return (
    <Box px={4}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={4}>
            <Grid item xs={12}>
              <Typography variant="h6">Informazioni anagrafiche</Typography>
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="name" label="Nome" />
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="surname" label="Cognome" />
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="email" label="Email" />
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="phone_number" label="Numero di telefono" />
            </Grid>
            <Grid item lg={6}>
              <Controller
                name="birthdate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value ?? null}
                    onChange={onChange}
                    label="Data di nascita"
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Indirizzo del candidato</Typography>
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="street_address" label="Indirizzo" />
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="city" label="Città" />
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="postal_code" label="Codice postale" />
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="region" label="Regione" />
            </Grid>
            <Grid item lg={6}>
              <RenderTextInput control={control} name="country" label="Nazione" />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Esperienze passate</Typography>
            </Grid>
            <Grid item xs={12}>
              <PastExperiences />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Altre info</Typography>
            </Grid>
            <Grid item lg={12}>
              <RenderTextInput
                control={control}
                name="presentation"
                label="Lettera di presentazione"
                multiline
              />
            </Grid>
            <Grid
              item
              lg={12}
              justifyContent={'center'}
              sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}
            >
              <LoadingButton variant="contained" type="submit" loading={isSavingLoading}>
                Salva
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
}

export function RenderTextInput({
  control,
  name,
  label,
  ...rest
}: {
  control: any;
  name: string;
  label: string;
  multiline?: boolean;
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...fieldRest } }) => (
        <TextField
          value={value || ''}
          {...fieldRest}
          label={label}
          fullWidth
          InputLabelProps={{ shrink: !!value }}
          {...rest}
        />
      )}
    />
  );
}
