import { AutoFixHighOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import { JP_TEXT_GENERATION_CHAT_POSITION, JP_TEXT_GENERATION_CHAT_WIDTH } from 'config';
import { SidebarContext } from 'contexts/SidebarContext';
import { useContext } from 'react';

interface WandProps {
  onOpeningSidebar: () => void;
}

export const WandGenerationChat = ({ onOpeningSidebar }: WandProps) => {
  const { handleToggle, sidebarStatus } = useContext(SidebarContext);

  const isGenerationChatOpen =
    sidebarStatus[JP_TEXT_GENERATION_CHAT_POSITION].sidebarName === 'jp_generation_chat' &&
    sidebarStatus[JP_TEXT_GENERATION_CHAT_POSITION].isOpen;

  return (
    <InputAdornment position="end">
      <Tooltip title="Apri la chat con l'assistente virtuale per generare nuovi contenuti">
        <IconButton
          edge="end"
          sx={{ '&:hover': { color: 'primary.main' } }}
          onClick={() => {
            !isGenerationChatOpen &&
              handleToggle(
                'jp_generation_chat',
                JP_TEXT_GENERATION_CHAT_POSITION,
                JP_TEXT_GENERATION_CHAT_WIDTH
              );
            onOpeningSidebar();
          }}
        >
          <AutoFixHighOutlined />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};
