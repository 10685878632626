import { useContext } from 'react';
import FilterList from '@mui/icons-material/FilterList';
// material
import { Box, Tooltip, useTheme } from '@mui/material';
//
import { MIconButton } from './@material-extend';

import { SidebarContext } from 'contexts/SidebarContext';
import { useLocation } from 'react-router';
import { SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH } from 'config';

// ----------------------------------------------------------------------

export default function SidebarFilterFAB() {
  const { sidebarStatus, handleToggle, isFABHidden } = useContext(SidebarContext);
  const { pathname } = useLocation();
  const theme = useTheme();

  const open =
    isFABHidden ||
    (sidebarStatus[SIDEBAR_FILTERS_POSITION].sidebarName === 'filter' &&
      sidebarStatus[SIDEBAR_FILTERS_POSITION].isOpen) ||
    false;

  // switch (pathname) {
  //   case '/restaurants':
  //   case '/employers':
  //   case '/job-positions':
  //     break;
  //   default:
  //     return null;
  // }

  if (
    !(
      pathname.startsWith('/job-positions/') ||
      pathname === '/employers' ||
      pathname === '/restaurants'
    )
  )
    return null;

  const handleOpenFilters = () => {
    handleToggle('filter', SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH);
  };

  return (
    <>
      <Box
        sx={{
          top: 12,
          bottom: 12,
          right: 0,
          position: 'fixed',
          zIndex: 500,
          ...(open && { right: -44 }),
          transition: theme.transitions.create('all')
        }}
      >
        <Box
          sx={{
            p: 0.5,
            px: '4px',
            mt: -3,
            left: -44,
            top: '50%',
            color: 'grey.800',
            position: 'absolute',
            bgcolor: 'common.white',
            borderRadius: '24px 0 16px 24px',
            boxShadow: (theme) => theme.customShadows.z12
          }}
        >
          <Tooltip title="Filtri">
            <MIconButton
              color="inherit"
              onClick={handleOpenFilters}
              sx={{
                p: 0,
                width: 40,
                height: 40,
                transition: (theme) => theme.transitions.create('all'),
                '&:hover': { color: 'primary.main', bgcolor: 'transparent' }
              }}
            >
              <FilterList />
            </MIconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}
