import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Dialog from 'components/Dialog';
import { Controller, useFormContext } from 'react-hook-form';
import { RenderTextInput } from '..';
import DomainInterest from './form/DomainInterest';
import Occupation from './form/Occupation';

export function EditItem({
  index,
  isOpen,
  onClose
}: {
  index: number;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { control } = useFormContext();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>Modifica esperienza passata</DialogTitle>
      <DialogContent>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} lg={6}>
            <DomainInterest index={index} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Occupation index={index} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name={`past_experiences.${index}.from_date`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ?? null}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} fullWidth label="Da" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name={`past_experiences.${index}.to_date`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ?? null}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} fullWidth label="A" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RenderTextInput
              control={control}
              name={`past_experiences.${index}.restaurant_name`}
              label="Nome locale"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RenderTextInput
              control={control}
              name={`past_experiences.${index}.city`}
              label="Città"
            />
          </Grid>
          <Grid item xs={12}>
            <RenderTextInput
              control={control}
              name={`past_experiences.${index}.description`}
              label="Descrizione dell'esperienza"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
}
