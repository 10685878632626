import { createContext, ReactNode, useCallback, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks';
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from 'config';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

interface AlgoliaSearchContextProps {
  isOpen: boolean;
  handleToggle: VoidFunction;
}

const initialState: AlgoliaSearchContextProps = {
  isOpen: false,
  handleToggle: () => {}
};

const AlgoliaSearchContext = createContext(initialState);

type AlgoliaSearchProviderProps = {
  children: ReactNode;
};

function AlgoliaSearchProvider({ children }: AlgoliaSearchProviderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <InstantSearch searchClient={searchClient} indexName="job_positions">
      <AlgoliaSearchContext.Provider
        value={{
          isOpen,
          handleToggle
        }}
      >
        {children}
      </AlgoliaSearchContext.Provider>
    </InstantSearch>
  );
}

export { AlgoliaSearchProvider, AlgoliaSearchContext };
