import { useContext, useEffect } from 'react';
import { GlobalOptionsFetchingContext } from 'contexts/GlobalOptionsFetchingContext';

// ----------------------------------------------------------------------

const useGlobalOptionsFetchingContext = ({
  statusPrefix,
  variableNames
}: {
  statusPrefix?: string[];
  variableNames?: string[];
} = {}) => {
  const context = useContext(GlobalOptionsFetchingContext);

  useEffect(() => {
    if (!variableNames) return;
    context.fetchVariable(variableNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.fetchVariable]);

  useEffect(() => {
    if (!statusPrefix) return;
    context.fetchStatus(statusPrefix);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.fetchStatus]);

  if (!context) throw new Error('Global context must be used inside the GlobalProvider');

  return context;
};

export default useGlobalOptionsFetchingContext;
