import { Container } from '@mui/system';
import * as React from 'react';

import useSettings from 'hooks/useSettings';
import Page from 'components/Page';

import SearchOrganization from './SearchOrganization';
import RestaurantForm from './RestaurantForm';
import { useQueryParams } from './RestaurantForm.utils';

interface IRestaurantOnboardingFormProps {}
type FormMode = 'create' | 'edit' | 'search';

const RestaurantOnboardingForm: React.FunctionComponent<IRestaurantOnboardingFormProps> = (
  props
) => {
  const getQuery = useQueryParams();
  const { themeStretch } = useSettings();

  const mode = getQuery('mode') as FormMode;

  return (
    <Page title="Restaurant Registration">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {mode === 'search' && <SearchOrganization />}
        {(mode === 'create' || mode === 'edit') && <RestaurantForm />}
      </Container>
    </Page>
  );
};

export default RestaurantOnboardingForm;
