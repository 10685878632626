import { DeleteOutline, DoubleArrow, EditOutlined } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Menu } from '@mui/material';
import { PromptItem } from '@restworld/data-services';

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleDeleteMessage: () => void;
  handleEditMessage: () => void;
  handleCopyMessage: () => void;
  role?: PromptItem['role'];
}

export default function ChatItemActionMenu({
  anchorEl,
  handleClose,
  handleDeleteMessage,
  handleEditMessage,
  handleCopyMessage,
  role
}: Props) {
  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem onClick={handleCopyMessage} disabled={role !== 'assistant'}>
        <ListItemIcon>
          <DoubleArrow color="success" sx={{ transform: 'rotate(180deg)' }} />
        </ListItemIcon>
        <ListItemText>Salva il messaggio nell'editor</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleEditMessage}>
        <ListItemIcon>
          <EditOutlined sx={{ color: 'grey.600' }} />
        </ListItemIcon>
        <ListItemText>Modifica</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleDeleteMessage} disabled={role === 'system'}>
        <ListItemIcon>
          <DeleteOutline sx={{ color: 'grey.600' }} />
        </ListItemIcon>
        <ListItemText>Cancella</ListItemText>
      </MenuItem>
    </Menu>
  );
}
