import { ENDPOINTS } from 'constants/api';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';
import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import { EntityId } from '@restworld/utility-types';
import {
  JobPositionBillingType,
  DashboardAvgJpClosingTime,
  DashboardHistoryActiveJP,
  DashboardRWMemberStats,
  DashboardWinRate,
  DashboardWorkersShortlistsSent,
  DashboardTimeFrame,
  DashboardTimeRange
} from '@restworld/data-services';

class ServiceDashboard extends Service {
  rwMemberStats(csmId?: EntityId) {
    return dynamicAxios(this.jwt).get<DashboardRWMemberStats>(ENDPOINTS.dashboard.rw_member_stats, {
      params: { csm_id: csmId }
    });
  }

  historyActiveJobPositions(
    jobPositionBillingType: JobPositionBillingType,
    timeFrame: DashboardTimeFrame,
    timeRange: DashboardTimeRange,
    csmId?: EntityId
  ) {
    return dynamicAxios(this.jwt).get<DashboardHistoryActiveJP>(
      ENDPOINTS.dashboard.history_active_job_positions,
      {
        params: {
          duration: timeRange,
          csm_id: csmId,
          time_frame: timeFrame,
          job_position_billing_type: jobPositionBillingType
        }
      }
    );
  }

  csmWinRate(csmId?: EntityId) {
    return dynamicAxios(this.jwt).get<DashboardWinRate>(ENDPOINTS.dashboard.csm_win_rate, {
      params: { csm_id: csmId }
    });
  }

  workersShortlistsSent(csmId?: EntityId) {
    return dynamicAxios(this.jwt).get<DashboardWorkersShortlistsSent>(
      ENDPOINTS.dashboard.average_shortlists_and_workers_sent,
      {
        params: { csm_id: csmId }
      }
    );
  }

  jpAvgClosingTime(csmId?: EntityId) {
    return dynamicAxios(this.jwt).get<DashboardAvgJpClosingTime>(
      ENDPOINTS.dashboard.job_position_average_closing_time,
      {
        params: { csm_id: csmId }
      }
    );
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceDashboard(jwt), [jwt]);
};
export default useService;
