import Loader from 'components/Loader';
import { useState, ReactNode, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

const allowedOutsourcingSalesRoutes = ['/history', '/registration-forms/organization'];

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isReady, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  const userRoles = useMemo(() => user?.data?.roles?.map((role: any) => role?.key), [user]);

  if (!isReady) return <Loader />;
  if (!isAuthenticated && isReady) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (
    userRoles &&
    userRoles.includes('outsourcing_sales') &&
    !allowedOutsourcingSalesRoutes.includes(pathname)
  ) {
    if (pathname === '/registration-forms') {
      return <Navigate to="/registration-forms/organization" />;
    }
    if (pathname?.split('/')?.at(4) !== 'start-subscription') {
      return <Navigate to="/history" />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
