import { Autocomplete, TextField } from '@mui/material';
import useTaxonomy from 'hooks/useTaxonomy';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

export default function Occupation({ index }: { index: number }) {
  const { control } = useFormContext();

  const { occupations, indexOccupations, isOccupationLoading } = useTaxonomy();
  const domainInterest = useWatch({
    control,
    name: `past_experiences.${index}.domain_interest_var`
  });
  useEffect(() => {
    indexOccupations(domainInterest?.id);
  }, [domainInterest, indexOccupations]);

  return (
    <Controller
      name={`past_experiences.${index}.occupation`}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Autocomplete
          options={occupations ?? []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label_it.label}
          value={value ?? null}
          loading={isOccupationLoading}
          onChange={(e, newValue) => onChange(newValue)}
          renderInput={(props) => <TextField {...props} fullWidth label="Ruolo" />}
        />
      )}
    />
  );
}
