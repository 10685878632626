import { AlgoliaSearchContext } from 'contexts/AlgoliaSearchContext';
import { useContext } from 'react';

export default function useAlgoliaSearch() {
  const context = useContext(AlgoliaSearchContext);

  if (!context)
    throw new Error('This component can only be executed inside a Algolia search provider');

  return context;
}
