import { Autocomplete, TextField } from '@mui/material';
import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export default function DomainInterest({ index }: { index: number }) {
  const { control } = useFormContext();
  const { variableOptions } = useGlobalOptionsFetchingContext({
    variableNames: ['domain_interest']
  });
  const domainInterestOptions = useMemo(() => variableOptions?.domain_interest, [variableOptions]);

  return (
    <Controller
      name={`past_experiences.${index}.domain_interest_var`}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Autocomplete
          options={domainInterestOptions ?? []}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={value ?? null}
          onChange={(e, newValue) => {
            onChange(newValue);
          }}
          renderInput={(props) => <TextField {...props} fullWidth label="Settore" />}
        />
      )}
    />
  );
}
