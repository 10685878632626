import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { it } from 'date-fns/locale';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import SidebarFilterFAB from 'components/SidebarFilterFAB';
import SidebarWorkerTimelineFAB from 'components/SidebarWorkerTimelineFAB';
import { GlobalOptionsFetchingProvider } from 'contexts/GlobalOptionsFetchingContext';
import { AlgoliaSearchProvider } from 'contexts/AlgoliaSearchContext';
import AlgoliaSearch from 'components/algolia';

import { CustomSnackbarProvider } from 'components/Snackbar/CustomSnackbarProvider';
import { DelayFunctionProvider } from 'contexts/DelayFunctionContext';

import { ChartStyle } from 'components/chart';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
            <AlgoliaSearchProvider>
              <CustomSnackbarProvider>
                <DelayFunctionProvider>
                  <GlobalOptionsFetchingProvider>
                    <GlobalStyles />
                    <ChartStyle />
                    <ProgressBarStyle />
                    {/* Sidebar floating components */}
                    <SidebarFilterFAB />
                    {/* <SidebarJPDetailFAB /> */}
                    <SidebarWorkerTimelineFAB />
                    <ScrollToTop />
                    <AlgoliaSearch />
                    <Router />
                  </GlobalOptionsFetchingProvider>
                </DelayFunctionProvider>
              </CustomSnackbarProvider>
            </AlgoliaSearchProvider>
          </LocalizationProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
