import * as React from 'react';
import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { EmployerUserContact } from '@restworld/utility-types';

import SelectFormInput from './SelectFormComponent';
import MultiSelectFormComponent from './MultiSelectFormComponent';

interface IReferDetailsFormProps {}

export const ReferDetailsForm: React.FunctionComponent<IReferDetailsFormProps> = (props) => {
  const queryClient = useQueryClient();
  const referentUserContacts = queryClient.getQueryData([
    'employer_contacts'
  ]) as EmployerUserContact[];
  const queryState = queryClient.getQueryState(['employer_contacts']);

  const {
    variableOptions,
    isLoading: isVariablesLoading,
    fetchVariable
  } = useGlobalOptionsFetchingContext();

  React.useEffect(() => {
    fetchVariable(['future_goal']);
  }, [fetchVariable]);

  const { future_goal } = variableOptions;

  return (
    <Grid item container rowGap={3}>
      <SelectFormInput
        formLabel="Che prospettive hai per il futuro prossimo del tuo locale?"
        formId="future_goal"
        options={future_goal?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
      />
      <MultiSelectFormComponent
        formLabel="Seleziona la persona di riferimento per questo locale"
        formId="referent_user_contacts"
        options={referentUserContacts?.map((v) => ({
          value: v.user_id,
          label: v.user?.name ?? v.user?.email
        }))}
        loading={queryState?.status === 'pending'}
        required={referentUserContacts?.length ? 'Questo campo è obbligatorio!' : false}
        placeholder="Seleziona uno o più referenti"
      />
    </Grid>
  );
};

export default ReferDetailsForm;
