import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useQueryClient } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { RWMemberRole } from '@restworld/utility-types';
import { Box, Chip, OutlinedInput } from '@mui/material';
import { AVAILABLE_ROLES } from './TeamMates';
import { RWMemberUpdatePayloadType } from '@restworld/data-services';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
export default function TeamMemberRolesSelector() {
  const queryClient = useQueryClient();
  const availableRoles = queryClient.getQueryData<RWMemberRole[]>([AVAILABLE_ROLES]);
  const {
    control,
    formState: { errors }
  } = useFormContext<RWMemberUpdatePayloadType>();
  return (
    <Controller
      name="roles"
      control={control}
      render={({ field }) => (
        <FormControl
          fullWidth
          sx={{
            mt: 4
          }}
          error={!!errors.roles}
        >
          <InputLabel id="roles">Ruoli</InputLabel>
          <Select
            labelId="roles"
            id="roles"
            multiple
            MenuProps={MenuProps}
            input={<OutlinedInput label="Ruolo" />}
            renderValue={() => {
              const selectedLabels = availableRoles
                ?.filter((v) => (field.value as string[]).includes(v.key))
                .map((v) => v.label);
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedLabels?.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              );
            }}
            {...field}
            onChange={(event) => {
              const {
                target: { value }
              } = event;
              field.onChange(typeof value === 'string' ? value.split(',') : value);
            }}
          >
            {availableRoles?.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {!!errors.roles && <FormHelperText>{errors.roles.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
