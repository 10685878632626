import { useContext, useMemo } from 'react';
import TimelineIcon from '@mui/icons-material/Timeline';
// material
import { Box, Tooltip, useTheme } from '@mui/material';
//
import { MIconButton } from './@material-extend';

import { SidebarContext } from 'contexts/SidebarContext';
import { useLocation } from 'react-router';
import { SIDEBAR_WORKER_TIMELINE_POSITION, SIDEBAR_WORKER_TIMELINE_WIDTH } from 'config';

// ----------------------------------------------------------------------

export default function SidebarWorkerTimelineFAB() {
  const { sidebarStatus, handleToggle, isFABHidden } = useContext(SidebarContext);
  const { pathname } = useLocation();
  const theme = useTheme();

  const open =
    isFABHidden ||
    (sidebarStatus[SIDEBAR_WORKER_TIMELINE_POSITION].sidebarName === 'worker_timeline' &&
      sidebarStatus[SIDEBAR_WORKER_TIMELINE_POSITION].isOpen) ||
    false;

  const isPathnameValid = useMemo(() => {
    if (pathname.split('/').length !== 3 || pathname.split('/')[1] !== 'workers') return false;
    return true;
  }, [pathname]);

  if (!isPathnameValid) return null;

  const handleOpenWorkerTimeline = () => {
    handleToggle(
      'worker_timeline',
      SIDEBAR_WORKER_TIMELINE_POSITION,
      SIDEBAR_WORKER_TIMELINE_WIDTH
    );
  };

  return (
    <>
      <Box
        sx={{
          top: 12,
          bottom: 12,
          left: SIDEBAR_WORKER_TIMELINE_POSITION === 'left' ? 0 : 'auto',
          right: SIDEBAR_WORKER_TIMELINE_POSITION === 'right' ? 0 : 'auto',
          position: 'fixed',
          zIndex: 2001,
          ...(open && { [SIDEBAR_WORKER_TIMELINE_POSITION]: -44 }),
          transition: theme.transitions.create(['all'])
        }}
      >
        <Box
          sx={{
            p: 0.5,
            px: '4px',
            mt: -3,
            [SIDEBAR_WORKER_TIMELINE_POSITION === 'right' ? 'left' : 'right']: -44,
            top: '50%',
            color: 'grey.800',
            position: 'absolute',
            bgcolor: 'common.white',
            borderRadius:
              SIDEBAR_WORKER_TIMELINE_POSITION === 'right'
                ? '24px 0 16px 24px'
                : '0 24px 24px 16px',
            boxShadow: (theme) => theme.customShadows.z12
          }}
        >
          <Tooltip title="Mostra lo storico">
            <MIconButton
              color="inherit"
              onClick={handleOpenWorkerTimeline}
              sx={{
                p: 0,
                width: 40,
                height: 40,
                transition: (theme) => theme.transitions.create('all'),
                '&:hover': { color: 'primary.main', bgcolor: 'transparent' }
              }}
            >
              <TimelineIcon />
            </MIconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}
