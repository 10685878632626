import { TextField, List, ListItem, Typography, useTheme, Box } from '@mui/material';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { Address, Restaurant, Worker } from '@restworld/utility-types';
// import { RestaurantUpdated } from 'pages/showRestaurant';

const ListStyled = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderRadius: '10px',
  borderColor: theme.palette.grey[400]
}));

type Props = {
  updateRestaurant?: (key: keyof Restaurant, value: any, addressInputs?: Address) => void;
  address: string;
  errors?: {
    field: 'address' | 'referent_contact' | undefined;
    error: boolean;
    text: string;
  };
  autoCompleteTypes: string[];
  updateWorker?: (key: keyof Worker, value: any, addressInputs?: Address) => void;
  label?: string;
  restaurantKey?: keyof Restaurant;
};

export default function AddressInput({
  errors,
  address,
  updateRestaurant,
  autoCompleteTypes,
  updateWorker,
  label,
  restaurantKey
}: Props) {
  const theme = useTheme();
  const {
    value,
    setValue,
    suggestions: { status, data }
  } = usePlacesAutocomplete({
    debounce: 500,
    requestOptions: {
      types: autoCompleteTypes
    }
  });
  const [isOpenAddressList, setIsOpenAddressList] = useState<boolean>(false);

  useEffect(() => {
    setValue(address);
  }, [address, setValue]);

  const handleAddressUpdate = (address: string) => {
    getGeocode({ address: address })
      .then((results) => {
        const country = results[0].address_components.find((c: any) =>
          c.types.includes('country')
        )?.long_name;
        const locality = results[0].address_components.find(
          (c: any) =>
            c.types.includes('locality') || c.types.includes('administrative_area_level_3')
        )?.long_name;
        const postal_code = getZipCode(results[0], false);
        const administrative_area_level_1 = results[0].address_components.find((c: any) =>
          c.types.includes('administrative_area_level_1')
        )?.long_name;
        const administrative_area_level_2 = results[0].address_components.find((c: any) =>
          c.types.includes('administrative_area_level_2')
        )?.long_name;
        const { lat, lng } = getLatLng(results[0]);
        const placeId = results[0].place_id;

        updateRestaurant &&
          restaurantKey &&
          updateRestaurant(restaurantKey, address, {
            address: address,
            address_lat: lat,
            address_lon: lng,
            locality: locality || '',
            country: country || '',
            postal_code: postal_code || '',
            administrative_area_level_1: administrative_area_level_1 || '',
            administrative_area_level_2: administrative_area_level_2 || '',
            gmaps_place_id: placeId
          });

        updateWorker &&
          updateWorker('address', address, {
            address: address,
            address_lat: lat,
            address_lon: lng,
            locality: locality || '',
            country: country || '',
            postal_code: postal_code || '',
            administrative_area_level_1: administrative_area_level_1 || '',
            administrative_area_level_2: administrative_area_level_2 || ''
          });
      })
      .catch((error) => console.error({ error }));
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const { place_id, description } = suggestion;

      return (
        <ListItem
          key={place_id}
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => {
            setIsOpenAddressList(false);
            setValue(description);
            handleAddressUpdate(description);
          }}
        >
          <Typography
            variant="body1"
            sx={{
              '&:hover': {
                fontWeight: 'bold'
              }
            }}
          >
            {description}
          </Typography>
        </ListItem>
      );
    });

  return (
    <Box>
      <TextField
        fullWidth
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setIsOpenAddressList(true);
        }}
        variant="outlined"
        label={label}
        error={errors?.field === 'address' && errors.error}
        helperText={errors?.field === 'address' && errors.error ? errors.text : ''}
      />
      {status === 'OK' && isOpenAddressList && (
        <ListStyled theme={theme}>{renderSuggestions()}</ListStyled>
      )}
    </Box>
  );
}
