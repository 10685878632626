import { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import * as Yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { IconButton, InputAdornment, Stack, TextField, Alert } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

type InputValues = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const loginFormSchema = Yup.object().shape({
    email: Yup.string().email("L'email non è valida").required("L'email è obbligatoria"),
    password: Yup.string().required('La password è obbligatoria')
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError
  } = useForm<InputValues>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
      afterSubmit: ''
    },
    resolver: yupResolver(loginFormSchema)
  });

  const onSubmit: SubmitHandler<InputValues> = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error: any) {
      setError('afterSubmit', { message: error.message });
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <TextField
          {...register('email', { required: true })}
          required
          fullWidth
          autoComplete="username"
          type="email"
          label="Indirizzo email"
          error={!!errors.email || false}
          helperText={!!errors.email && errors.email.message}
        />
        <TextField
          {...register('password', { required: true })}
          fullWidth
          required
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          error={!!errors.password || false}
          helperText={!!errors.password && errors.password.message}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Stack>
    </form>
  );
}
