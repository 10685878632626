import { Box, Stack, Typography } from '@mui/material';
import Label, { LabelColor } from 'components/Label';
import { formatDistanceToNowStrict } from 'date-fns';
import { it } from 'date-fns/locale';
import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { useMemo } from 'react';
import { getDateFromSeconds, getLabelColor } from 'utils/common';

interface Props {
  hit: any;
}

export default function JobPositionHit({ hit }: Props) {
  const { statusOptions } = useGlobalOptionsFetchingContext({
    statusPrefix: ['jp_']
  });

  const status = useMemo(
    () => statusOptions?.jp_?.find((status) => status.key === hit.status_key),
    [hit.status_key, statusOptions?.jp_]
  );

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <Typography variant="caption">{hit.restaurant.restaurant_name}</Typography>
        {!!hit.inserted_at && (
          <Typography variant="caption" sx={{ color: 'grey.600' }}>
            - avviata{' '}
            {formatDistanceToNowStrict(getDateFromSeconds(hit.inserted_at), { locale: it })} fa
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1">
          {hit.occupation?.label_it?.label ||
            hit.job_title ||
            hit.job_specialization_var?.label ||
            '-'}
        </Typography>
        {!!hit.status_key && (
          <Label color={getLabelColor(status?.sentiment) as LabelColor}>{status?.label}</Label>
        )}
      </Stack>
    </Box>
  );
}
