import { ServiceProvider } from '@restworld/data-services';
import useAuth from 'hooks/useAuth';
import { ReactNode } from 'react';

interface Props {
  baseUrl: string;
  children: ReactNode;
}

export default function ServiceProviderWrapper({ baseUrl, children }: Props) {
  const { jwt } = useAuth();

  return (
    <ServiceProvider baseUrl={baseUrl} jwt={jwt}>
      {children}
    </ServiceProvider>
  );
}
