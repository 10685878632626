import { Download } from '@mui/icons-material';
import { IconButton, Link } from '@mui/material';
import { Paper, Stack, Tooltip } from '@mui/material';
import SkipCv from './skip-cv';
import { Typography } from '@mui/material';
import Label, { LabelColor } from 'components/Label';

interface Props {
  cvUrl: string;
  handleSkipCv: () => void;
  isSkippingLoading: boolean;
  correctedCvCount: null | number;
}
export default function Navbar({
  cvUrl,
  handleSkipCv,
  isSkippingLoading,
  correctedCvCount
}: Props) {
  return (
    <Paper
      // elevation={3}
      sx={{ width: '100%', borderRadius: '10px', bgcolor: 'grey.100', mt: 4, mb: 2 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2, px: 3 }}
      >
        <Tooltip title="Scarica il CV">
          <IconButton component={Link} href={cvUrl}>
            <Download />
          </IconButton>
        </Tooltip>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h4">Correggi le informazioni sbagliate o mancanti</Typography>
          <CurrentCount count={correctedCvCount} />
        </Stack>
        <SkipCv onConfirm={handleSkipCv} isSkippingLoading={isSkippingLoading} />
      </Stack>
    </Paper>
  );
}

function CurrentCount({ count }: { count: null | number }) {
  let content = '';
  if (count === null) content = 'Caricamento...';
  else content = `Oggi hai corretto ${count} CV`;

  let color: LabelColor = 'default';
  if (count !== null && count > 5) color = 'warning';
  if (count !== null && count >= 10) color = 'success';

  return <Label color={color}>{content}</Label>;
}
