import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { InfoOutlined } from '@mui/icons-material';

import { StyledInputLabel } from 'pages/onboarding/JobPositionForm/components';

import { IRestaurantFormValues } from '../RestaurantForm.types';
import { useAddressAdditionalFields, useAddressInput } from '../RestaurantForm.utils';

interface IAddressInputFormProps {
  formId: keyof IRestaurantFormValues;
  formLabel: string;
  labelInfoText?: string;
  required?: boolean;
}

const AddressInputForm: React.FunctionComponent<IAddressInputFormProps> = ({
  formId,
  formLabel,
  labelInfoText,
  required
}) => {
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext<IRestaurantFormValues>();
  const { renderSuggestions, handleInput, handleSelection, status, selectedOption } =
    useAddressInput({});
  const addressFields = useAddressAdditionalFields({ addressString: selectedOption });
  useEffect(() => {
    if (addressFields && Object.keys(addressFields).length) {
      setValue('address_lat', addressFields.address_lat);
      setValue('address_lon', addressFields.address_lon);
      setValue('country', addressFields.country);
      setValue('address', addressFields.address);
      setValue('administrative_area_level_1', addressFields.administrative_area_level_1);
      setValue('administrative_area_level_2', addressFields.administrative_area_level_2 ?? '');
      setValue('postal_code', addressFields.postal_code ?? '');
      setValue('locality', addressFields.locality ?? '');
      setValue('gmaps_place_id', addressFields?.gmaps_place_id);
    }
  }, [addressFields, setValue]);

  return (
    <>
      <Grid item container direction="column" key={formId}>
        <StyledInputLabel
          sx={{
            justifyItems: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: 0.25
          }}
          htmlFor={formId}
          required={required}
        >
          <div>{formLabel}</div>
          {!!labelInfoText && (
            <Tooltip title={labelInfoText} arrow>
              <InfoOutlined fontSize="small" />
            </Tooltip>
          )}
        </StyledInputLabel>
        <Controller
          name={formId}
          control={control}
          rules={{ required: required ? 'Questo campo è obbligatorio!' : false }}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              id={formId}
              options={status === 'OK' ? renderSuggestions() : []}
              noOptionsText={status === 'ZERO_RESULTS' ? 'Nessuna posizione trovata' : ''}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder={`Ricerca ${formLabel.toLowerCase()}...`}
                    error={!!errors[formId]}
                    helperText={!!errors[formId] ? (errors[formId]?.message as string) : ''}
                    {...{
                      onChange: (e) => {
                        handleInput(e.target.value);
                      }
                    }}
                  />
                );
              }}
              {...field}
              onChange={(_, selectedValue) => {
                if (selectedValue) handleSelection(selectedValue as string);
                field.onChange(selectedValue);
              }}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default AddressInputForm;
