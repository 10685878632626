import { Box, Typography } from '@mui/material';

interface Props {
  hit: any;
}

export default function RestaurantHit({ hit }: Props) {
  return (
    <Box>
      <Typography variant="subtitle1">{hit.restaurant_name}</Typography>
      <Typography variant="caption" sx={{ color: 'grey.600' }}>
        {hit.address}
      </Typography>
    </Box>
  );
}
