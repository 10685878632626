import { FormHelperText, Grid } from '@mui/material';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { CircularProgress } from '@mui/material';

import { StyledInputLabel } from 'pages/onboarding/JobPositionForm/components';

import { IRestaurantFormValues, ISelectFormInputProps } from '../RestaurantForm.types';

const SelectFormInput: React.FunctionComponent<ISelectFormInputProps> = ({
  formLabel,
  options,
  formId,
  loading,
  required,
  renderMenuItems
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext<IRestaurantFormValues>();

  return (
    <Grid item container key={formId}>
      <Grid item container gap={2}>
        <StyledInputLabel htmlFor={formId}>{formLabel}</StyledInputLabel>
        {loading && <CircularProgress size={20} />}
      </Grid>
      <Controller
        name={formId}
        control={control}
        rules={{ required }}
        render={({ field }) => {
          return (
            <Select
              id={formId}
              fullWidth
              error={!!errors[formId]}
              labelId={formId}
              // displayEmpty
              {...field}
            >
              <MenuItem disabled value="">
                {loading ? 'Caricamento opzioni...' : `Seleziona`}
              </MenuItem>
              {renderMenuItems
                ? renderMenuItems
                : options?.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
            </Select>
          );
        }}
      />
      {!!errors[formId] && <FormHelperText error>{errors[formId]?.message}</FormHelperText>}
    </Grid>
  );
};

export default SelectFormInput;
