import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useAtom } from 'jotai';

import {
  searchVectorAtom,
  teamMatesTablePageNumberAtom,
  teamMatesTablePaginationAtom
} from './TeamMatesTable';

export default function TeamMateSearch() {
  const [searchText, setSearchText] = useAtom(searchVectorAtom);
  const [, setTeamMatesTablePagination] = useAtom(teamMatesTablePaginationAtom);
  const [, setPage] = useAtom(teamMatesTablePageNumberAtom);
  return (
    <Box
      sx={{
        maxWidth: '70%',
        mx: 'auto',
        marginTop: '16px'
      }}
    >
      <TextField
        fullWidth
        label="Cerca i membri del team"
        id="fullWidth"
        onChange={(e) => {
          setSearchText(e.target.value);
          setTeamMatesTablePagination({ limit: 7, offset: 0 });
          setPage(0);
        }}
        value={searchText}
      />
    </Box>
  );
}
