import { Dialog, DialogTitle } from '@mui/material';
import OrganizationStructuredNote from './OrganizationStructuredNote';
import { OrgnaizationStructuredNote } from 'service/organizations';
import { useCallback } from 'react';
import { useOrganizationService } from 'service';

type Props = {
  isOpen: boolean;
  organizationId: string;
  onClose: () => void;
  inputStructuredNote?: OrgnaizationStructuredNote;
  refresh?: () => void;
  viewMode?: boolean;
  insertMode?: boolean;
};

export default function OrganizationStructuredNoteDialog({
  isOpen,
  onClose,
  inputStructuredNote,
  refresh,
  viewMode,
  organizationId,
  insertMode
}: Props) {
  const organizationService = useOrganizationService();
  const updateOrganizationStructuredNote = useCallback(
    (note: Partial<OrgnaizationStructuredNote>) => {
      insertMode
        ? organizationService
            .createOrganizationStructuredNote(note)
            .then((res) => {
              onClose();
              refresh && refresh();
            })
            .catch((error) => {
              console.error({ error });
            })
        : organizationService
            .updateOrganizationStructuredNote(note)
            .then(() => {
              onClose();
              refresh && refresh();
            })
            .catch((error) => {
              console.error({ error });
            });
    },
    [organizationService, onClose, refresh, insertMode]
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{viewMode ? 'Onboarding notes' : 'Modifica onboarding notes'}</DialogTitle>
      <OrganizationStructuredNote
        inputNote={{ ...inputStructuredNote, organization_id: organizationId }}
        onClose={onClose}
        handleUpsert={updateOrganizationStructuredNote}
        viewMode={viewMode}
      />
    </Dialog>
  );
}
