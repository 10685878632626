import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Delete, Edit, MoreVertOutlined } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { atom, useAtom, useSetAtom } from 'jotai';
import { RWMember } from 'service/rw_members';

import { selectedTeamMatesAtom } from './TeamMatesTable';

export const showAnchorElAtom = atom<null | HTMLElement>(null);
export const showDialogAtom = atom<{ visible: boolean; id?: 'edit' | 'delete' | 'create' | '' }>({
  visible: false
});
export default function TeamMatesOptions({ teamData }: { teamData?: RWMember }) {
  const setAnchorEl = useSetAtom(showAnchorElAtom);
  const setSelectedTeamMatesAtom = useSetAtom(selectedTeamMatesAtom);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setSelectedTeamMatesAtom(teamData);
    },
    [setAnchorEl, setSelectedTeamMatesAtom, teamData]
  );

  return (
    <IconButton onClick={handleClick}>
      <MoreVertOutlined />
    </IconButton>
  );
}
export const TeamMatesOptionMenu = () => {
  const [anchorEl, setAnchorEl] = useAtom(showAnchorElAtom);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'team-mates-options'
      }}
    >
      <EditMenuButton />
      <DeleteMenuButton />
    </Menu>
  );
};
const EditMenuButton = () => {
  const [, setAnchorEl] = useAtom(showAnchorElAtom);
  const [, setVisible] = useAtom(showDialogAtom);

  return (
    <>
      <MenuItem
        disableRipple
        onClick={() => {
          setVisible({
            visible: true,
            id: 'edit'
          });
          setAnchorEl(null);
        }}
      >
        <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">Modifica</Typography>
        </ListItemText>
      </MenuItem>
    </>
  );
};
const DeleteMenuButton = () => {
  const [, setAnchorEl] = useAtom(showAnchorElAtom);
  const [, setVisible] = useAtom(showDialogAtom);

  return (
    <>
      <MenuItem
        disableRipple
        onClick={() => {
          setVisible({
            visible: true,
            id: 'delete'
          });
          setAnchorEl(null);
        }}
      >
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">Elimina</Typography>
        </ListItemText>
      </MenuItem>
    </>
  );
};
