import CircularProgress from '@mui/material/CircularProgress';
import { LoaderBackground, Loader as LoaderContainer } from '../styles/common';
import { SxProps } from '@mui/system';

interface Props {
  position?: 'relative' | 'absolute';
}

const relativePositionStyles = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative'
} as SxProps;

export default function Loader({ position }: Props) {
  return (
    <LoaderBackground sx={position === 'relative' ? relativePositionStyles : undefined}>
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    </LoaderBackground>
  );
}
