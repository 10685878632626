import { MAIN_PATHS } from 'routes/paths';

// ENDPOINT
export const HOST_ENDPOINT_BASE_URL =
  process.env.REACT_APP_HOST_ENDPOINT || 'https://stage.restworld.it/';

// Old LAB URL
export const OLD_LAB_URL = process.env.REACT_APP_OLD_LAB;

// ROOT PAGE AFTER LOGIN
export const PATH_AFTER_LOGIN = MAIN_PATHS.root;

// ALGOLIA
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID || '';
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY || '';

// GMAPS
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API;

// UI
type Position = 'right' | 'left';

export const SIDEBAR_TRANSITION_DURATION = 300; // milliseconds
export const SIDEBAR_FILTERS_WIDTH = '20%';
export const SIDEBAR_FILTERS_POSITION: Position = 'right';

export const SIDEBAR_MANAGEMENT_WIDTH = '0';
export const SIDEBAR_MANAGEMENT_MIN_VISIBLE_WIDTH = '25%';
export const SIDEBAR_MANAGEMENT_MAX_VISIBLE_WIDTH = '35%';
export const SIDEBAR_MANAGEMENT_POSITION: Position = 'right';

export const SIDEBAR_JP_DETAIL_WIDTH = '25%';
export const SIDEBAR_JP_DETAIL_EDITING_WIDTH = '50%';
export const SIDEBAR_JP_DETAIL_POSITION: Position = 'left';

export const SIDEBAR_EMPLOYER_FOLLOWUP = 'employer-followups';
export const SIDEBAR_EMPLOYER_FOLLOWUP_POSITION = 'right';
export const SIDEBAR_EMPLOYER_FOLLOWUP_WIDTH = '30%';
export const DEFAULT_FOLLOWUPS_LIMIT = 9;

export const SIDEBAR_RESTAURANT_WIDTH = '0';
export const SIDEBAR_RESTAURANT_VISIBLE_WIDTH = '30%';
export const SIDEBAR_RESTAURANT_POSITION: Position = 'right';

export const SIDEBAR_POSITION_DETAILS_WIDTH = '30%';
export const SIDEBAR_POSITION_DETAILS_POSITION: Position = 'right';

export const SIDEBAR_EMPLOYER_DETAILS_WIDTH = '30%';
export const SIDEBAR_EMPLOYER_DETAILS_POSITION: Position = 'right';

export const SIDEBAR_WORKER_TIMELINE_WIDTH = '20%';
export const SIDEBAR_WORKER_TIMELINE_POSITION: Position = 'right';

export const SIDEBAR_WORKER_DETAILS_WIDTH = '30%';
export const SIDEBAR_WORKER_DETAILS_POSITION = 'right';

export const SIDEBAR_SHORTLIST_DETAILS_WIDTH = '35%';
export const SIDEBAR_SHORTLIST_DETAILS_POSITION = 'right';

export const JP_TEXT_GENERATION_CHAT_WIDTH = '40%';
export const JP_TEXT_GENERATION_CHAT_POSITION = 'right';

// DATES
export const DATE_FORMAT_STRING = 'dd/MM/yyyy';

// Delaying functionality
// in msec
export const DEFAULT_ACTION_DELAY = 3000;

export const REACT_APP_PUBLIC_NEW_EMPLOYER_FLAG =
  process.env.REACT_APP_PUBLIC_NEW_EMPLOYER_FLAG === 'true';
