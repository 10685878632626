import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import { useScript } from 'usehooks-ts';
import { GMAPS_API_KEY } from 'config';
import CvParsingFeedback from 'pages/cvParsingFeedback';
import TeamMates from 'components/TeamMates/TeamMates';
import RestaurantOnboardingForm from 'pages/onboarding/RestaurantForm';
// import JobPositionRegistrationForm from 'pages/jobPositionRegistrationForm';

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const gmapsScript = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${GMAPS_API_KEY}&libraries=places`
  );

  useEffect(() => {
    if (gmapsScript === 'error') console.error('Error loading GMAPS Script');
  }, [gmapsScript]);

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    // Main Routes
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/home" replace /> },
        {
          path: 'home',
          element: (
            <AuthGuard>
              <CSMHome />
            </AuthGuard>
          )
        },
        {
          path: 'metrics',
          element: (
            <AuthGuard>
              <Metrics />
            </AuthGuard>
          )
        },
        {
          path: 'invoicing',
          element: (
            <AuthGuard>
              <Invoicing />
            </AuthGuard>
          )
        },
        {
          path: 'taxonomy',
          element: (
            <AuthGuard>
              <Taxonomy />
            </AuthGuard>
          )
        },
        {
          path: 'cv-parsing-feedback',
          element: (
            <AuthGuard>
              <CvParsingFeedback />
            </AuthGuard>
          )
        },
        {
          path: 'organizations',
          children: [
            {
              path: '',
              element: (
                <AuthGuard>
                  <EmployerListing />
                </AuthGuard>
              )
            },
            {
              path: ':id',
              element: (
                <AuthGuard>
                  <ShowEmployer />
                </AuthGuard>
              )
            }
          ]
        },
        {
          path: 'restaurants',
          children: [
            {
              path: '',
              element: (
                <AuthGuard>
                  <RestaurantListing />
                </AuthGuard>
              )
            },
            {
              path: ':id',
              element: (
                <AuthGuard>
                  <ShowRestaurant />
                </AuthGuard>
              )
            }
          ]
        },
        {
          path: 'job-positions',
          children: [
            {
              path: '',
              element: (
                <AuthGuard>
                  <JobPositionsListing />
                </AuthGuard>
              )
            },
            {
              path: ':id',
              element: (
                <AuthGuard>
                  <ShowJobPosition />
                </AuthGuard>
              )
            }
          ]
        },
        {
          path: 'workers',
          children: [
            {
              path: ':id',
              element: (
                <AuthGuard>
                  <ShowWorker />
                </AuthGuard>
              )
            }
          ]
        },
        {
          path: 'registration-forms',
          children: [
            {
              path: '',
              element: (
                <AuthGuard>
                  <Onboarding />
                </AuthGuard>
              )
            },
            {
              path: 'job-position',
              element: (
                <AuthGuard>
                  <JobPositionRegistrationForm />
                </AuthGuard>
              )
            },
            {
              path: 'restaurant',
              element: (
                <AuthGuard>
                  <RestaurantOnboardingForm />
                </AuthGuard>
              )
            },
            {
              path: 'organization',
              children: [
                {
                  path: '',
                  element: (
                    <AuthGuard>
                      <OrganizationRegistrationForm />
                    </AuthGuard>
                  )
                },
                {
                  path: 'company/start-subscription',
                  children: [
                    {
                      path: ':id',
                      element: (
                        <AuthGuard>
                          <CompanySubscriptions />
                        </AuthGuard>
                      )
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'history',
          children: [
            {
              path: '',
              element: (
                <AuthGuard>
                  <History />
                </AuthGuard>
              )
            }
          ]
        },
        {
          path: 'team-mates',
          children: [
            {
              path: '',
              element: (
                <AuthGuard>
                  <TeamMates />
                </AuthGuard>
              )
            }
          ]
        }
      ]
    },

    // Default Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
const Login = Loadable(lazy(() => import('pages/authentication/Login')));

// Dashboard
const CSMHome = Loadable(lazy(() => import('pages/csmHome')));
const Metrics = Loadable(lazy(() => import('pages/metrics')));
const Invoicing = Loadable(lazy(() => import('pages/invoicing')));
const Taxonomy = Loadable(lazy(() => import('pages/taxonomy')));
const EmployerListing = Loadable(lazy(() => import('pages/organizationsListing')));
const RestaurantListing = Loadable(lazy(() => import('pages/restaurantListing')));
const JobPositionsListing = Loadable(lazy(() => import('pages/jobPositionsListing')));
const ShowEmployer = Loadable(lazy(() => import('pages/showOrganization')));
const ShowRestaurant = Loadable(lazy(() => import('../pages/showRestaurant')));
const ShowJobPosition = Loadable(lazy(() => import('pages/showJobPosition')));
const Onboarding = Loadable(lazy(() => import('pages/onboarding')));
const JobPositionRegistrationForm = Loadable(
  lazy(() => import('pages/onboarding/JobPositionForm'))
);
const OrganizationRegistrationForm = Loadable(lazy(() => import('pages/onboarding/EmployerForm')));
const ShowWorker = Loadable(lazy(() => import('pages/showWorker')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const History = Loadable(lazy(() => import('pages/history')));
const CompanySubscriptions = Loadable(
  lazy(() => import('pages/onboarding/EmployerForm/employerSubscriptions'))
);
