import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';

interface Props {
  cvUrl: string;
}
export default function ShowOriginalCv({ cvUrl }: Props) {
  const [numPages, setNumPages] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Box ref={containerRef}>
      <Document file={cvUrl} onLoadSuccess={onDocumentLoadSuccess} loading="Caricamento del CV...">
        {!!numPages &&
          Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={containerRef.current?.offsetWidth}
              renderAnnotationLayer={false}
            />
          ))}
      </Document>
    </Box>
  );
}
