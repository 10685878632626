import { AxiosRequestConfig, AxiosResponse, AxiosPromise, AxiosHeaders } from 'axios';
import { isObject, removeUndefined } from './common';

const API_CACHE_KEY = 'apiCacheStore';

class CacheStore {
  private data: any = {};

  private useLocalStorage: boolean;

  constructor(useLocalStorage: boolean) {
    this.useLocalStorage = useLocalStorage;
    if (useLocalStorage) {
      const localstorageCache = localStorage.getItem(API_CACHE_KEY);
      if (localstorageCache) this.data = JSON.parse(localstorageCache);
    }
  }

  add(url: string, value: any) {
    this.data[url] = value;

    if (this.useLocalStorage) {
      localStorage.setItem(API_CACHE_KEY, JSON.stringify(this.data));
    }
  }

  get(url: string) {
    return this.data[url];
  }
}

export const apiCacheStore = new CacheStore(false);

export default class ApiCache {
  private cacheStore: CacheStore;

  constructor() {
    this.cacheStore = apiCacheStore; // using false cause data can become more than 5 mbs - localStorage max size is 5mb
  }

  assignAdapter(response: AxiosResponse, config: AxiosRequestConfig) {
    function adapter(config: AxiosRequestConfig): AxiosPromise {
      return new Promise((resolve) => {
        return resolve(response);
      });
    }
    config.adapter = adapter;
  }

  handleRequest(config: AxiosRequestConfig) {
    if (
      config.headers &&
      (config.headers as AxiosHeaders).get('useCache') &&
      config.url &&
      config.baseURL
    ) {
      const url = `${new URL(config.url, config.baseURL).toString()}?${new URLSearchParams(
        removeUndefined(config.params)
      )}`;
      const cachedData = this.cacheStore.get(url);
      if (cachedData) {
        this.assignAdapter(cachedData, config);
      }
    }
    if (
      config &&
      config.headers &&
      (config.headers as AxiosHeaders).get('useCache') !== undefined
    ) {
      (config.headers as AxiosHeaders).delete('useCache');
    }
  }

  handleResponse(response: AxiosResponse) {
    const config = response.config;
    const url = `${new URL(config.url || '', config.baseURL).toString()}?${new URLSearchParams(
      removeUndefined(config.params)
    )}`;
    if (isObject(response.data)) {
      response.data.cacheUrl = url;
    }
    this.cacheStore.add(url, response);
    return response;
  }
}
