// scroll bar
import 'simplebar/src/simplebar.css';

import { createRoot } from 'react-dom/client';
// import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { DevTools } from 'jotai-devtools';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/JWTContext';
import { SidebarFiltersProvider } from 'contexts/SidebarContext';
import { HOST_ENDPOINT_BASE_URL } from 'config';
import ServiceProviderWrapper from 'components/contextWrappers/ServiceProviderWrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// ----------------------------------------------------------------------
const queryClient = new QueryClient();
const RootApp = (
  // <StrictMode>
  <>
    <DevTools />
    <HelmetProvider>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <AuthProvider>
              <ServiceProviderWrapper baseUrl={HOST_ENDPOINT_BASE_URL}>
                <SidebarFiltersProvider>
                  <QueryClientProvider client={queryClient}>
                    <App />
                  </QueryClientProvider>
                </SidebarFiltersProvider>
              </ServiceProviderWrapper>
            </AuthProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </HelmetProvider>
  </>
  // </StrictMode>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(RootApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
