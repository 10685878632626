import { useCallback, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import { OrgnaizationStructuredNote } from 'service/organizations';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { formatDate } from 'utils/common';
import { DetailsItem } from './RestaurantDetailView';
import { Edit } from '@mui/icons-material';

type Props = {
  handleUpsert?: (note: Partial<OrgnaizationStructuredNote>) => void;
  inputNote?: Partial<OrgnaizationStructuredNote>;
  isLoading?: boolean;
  onClose?: () => void;
  viewMode?: boolean;
};

const getPurchasedProductLabel = (key?: string) => {
  switch (key) {
    case 'flexible_slot':
      return 'Flex Slot';
    case 'annual_subscription':
      return 'Abbonamento annuale';
    default:
      return '';
  }
};

const getClientRelevanceLabel = (key?: string) => {
  switch (key) {
    case 'low':
      return 'Bassa';
    case 'medium':
      return 'Media';
    case 'high':
      return 'Alta';
    default:
      return '';
  }
};

export default function OrganizationStructuredNote({
  isLoading,
  inputNote,
  onClose,
  handleUpsert,
  viewMode: inputViewMode
}: Props) {
  const [viewMode, setViewMode] = useState<boolean | undefined>(inputViewMode);
  const methods = useForm<Partial<OrgnaizationStructuredNote>>({
    defaultValues: { ...inputNote }
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const onSubmit: SubmitHandler<Partial<OrgnaizationStructuredNote>> = useCallback(
    (data) => {
      handleUpsert && handleUpsert(data);
    },
    [handleUpsert]
  );

  return (
    <>
      {viewMode ? (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="flex-end">
              <IconButton onClick={() => setViewMode(false)}>
                <Edit />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Prodotto acquistato</Typography>
              <DetailsItem>{getPurchasedProductLabel(inputNote?.purchased_product)}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Numero di slot acquistati</Typography>
              <DetailsItem>{inputNote?.number_of_flex_slots_to_purchase}</DetailsItem>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Nome Locale</Typography>
              <DetailsItem>{inputNote?.restaurant_name}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Tipologia locale</Typography>
              <DetailsItem>{inputNote?.business_type}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Città</Typography>
              <DetailsItem>{inputNote?.city}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Provincia</Typography>
              <DetailsItem>{inputNote?.province}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Stato</Typography>
              <DetailsItem>{inputNote?.country}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Sito web</Typography>
              <DetailsItem>{inputNote?.website}</DetailsItem>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Figura ricercata</Typography>
              <DetailsItem>{inputNote?.occupation}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Numero figure ricercate</Typography>
              <DetailsItem>{inputNote?.number_of_people_required}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Contratto e monte ore</Typography>
              <DetailsItem>{inputNote?.contract_and_working_hours}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Range di stipendio</Typography>
              <DetailsItem>{inputNote?.salary_range}</DetailsItem>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Referente</Typography>
              <DetailsItem>{inputNote?.referent}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Numero locali</Typography>
              <DetailsItem>{inputNote?.number_of_restaurants}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Numero collaboratori</Typography>
              <DetailsItem>{inputNote?.number_of_collaborators}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Proiezione assunzioni nell'anno corrente</Typography>
              <DetailsItem>{inputNote?.forecasted_hiring_in_current_year}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Esperienza pregressa in ricerca e selezione
              </Typography>
              <DetailsItem>{inputNote?.past_experience_in_hr_recruiting}</DetailsItem>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ m: 1 }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Attitudine referente</Typography>
              <DetailsItem>{inputNote?.referent_attitude}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Portata: rilevanza del cliente</Typography>
              <DetailsItem>{getClientRelevanceLabel(inputNote?.client_relevance)}</DetailsItem>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Note eventuali</Typography>
              <DetailsItem>{inputNote?.free_notes}</DetailsItem>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <FormProvider {...methods}>
          <form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="purchased_product"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth>
                          <InputLabel id="product-select-label">Prodotto acquistato</InputLabel>
                          <Select
                            fullWidth
                            value={value || ''}
                            labelId="product-select-label"
                            label="Prodotto acquistato"
                            onChange={(e) => {
                              onChange(e.target.value as string);
                            }}
                            error={!!errors.purchased_product}
                          >
                            <MenuItem value="flexible_slot">Flex Slot</MenuItem>
                            <MenuItem value="annual_subscription">Abbonamento annuale</MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('number_of_flex_slots_to_purchase')}
                    type="number"
                    fullWidth
                    label="Numero di slot acquistati"
                    error={!!errors?.number_of_flex_slots_to_purchase}
                    helperText={errors?.number_of_flex_slots_to_purchase?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ m: 1 }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('restaurant_name')}
                    fullWidth
                    label="Nome locale"
                    error={!!errors?.restaurant_name}
                    helperText={errors?.restaurant_name?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('business_type')}
                    fullWidth
                    label="Tipologia locale"
                    error={!!errors?.business_type}
                    helperText={errors?.business_type?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('city')}
                    fullWidth
                    label="Città"
                    error={!!errors?.city}
                    helperText={errors?.city?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('province')}
                    fullWidth
                    label="Provincia"
                    error={!!errors?.province}
                    helperText={errors?.province?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('country')}
                    fullWidth
                    label="Stato"
                    error={!!errors?.country}
                    helperText={errors?.country?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('website')}
                    fullWidth
                    label="Sito web"
                    error={!!errors?.website}
                    helperText={errors?.website?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ m: 1 }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('occupation')}
                    fullWidth
                    label="Figura ricercata"
                    error={!!errors?.occupation}
                    helperText={errors?.occupation?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('number_of_people_required')}
                    type="number"
                    fullWidth
                    label="Numero Figure ricercate"
                    error={!!errors?.number_of_people_required}
                    helperText={errors?.number_of_people_required?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('contract_and_working_hours')}
                    fullWidth
                    label="Contratto e monte ore"
                    error={!!errors?.contract_and_working_hours}
                    helperText={errors?.contract_and_working_hours?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('salary_range')}
                    fullWidth
                    label="Range di stipendio"
                    error={!!errors?.salary_range}
                    helperText={errors?.salary_range?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ m: 1 }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('referent')}
                    fullWidth
                    label="Referente (se diverso da quello registrato)"
                    error={!!errors?.referent}
                    helperText={errors?.referent?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('number_of_restaurants')}
                    type="number"
                    fullWidth
                    label="Numero locali"
                    error={!!errors?.number_of_restaurants}
                    helperText={errors?.number_of_restaurants?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('number_of_collaborators')}
                    type="number"
                    fullWidth
                    label="Numero collaboratori"
                    error={!!errors?.number_of_collaborators}
                    helperText={errors?.number_of_collaborators?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('forecasted_hiring_in_current_year')}
                    type="number"
                    fullWidth
                    label="Proiezione assunzioni nell'anno corrente"
                    error={!!errors?.forecasted_hiring_in_current_year}
                    helperText={errors?.forecasted_hiring_in_current_year?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('past_experience_in_hr_recruiting')}
                    fullWidth
                    label="Esperienza pregressa in ricerca e selezione"
                    error={!!errors?.past_experience_in_hr_recruiting}
                    helperText={errors?.past_experience_in_hr_recruiting?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ m: 1 }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('referent_attitude')}
                    fullWidth
                    label="Attitudine referente"
                    error={!!errors?.referent_attitude}
                    helperText={errors?.referent_attitude?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="client_relevance"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth>
                          <InputLabel id="client-relevance-select-label">
                            Portata del cliente
                          </InputLabel>
                          <Select
                            fullWidth
                            value={value || ''}
                            labelId="client-relevance-select-label"
                            label="Portata del cliente"
                            onChange={(e) => {
                              onChange(e.target.value as string);
                            }}
                            error={!!errors.client_relevance}
                          >
                            <MenuItem value="low">Bassa</MenuItem>
                            <MenuItem value="medium">Media</MenuItem>
                            <MenuItem value="high">Alta</MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('free_notes')}
                    multiline
                    fullWidth
                    label="Note eventuali"
                    error={!!errors?.free_notes}
                    helperText={errors?.free_notes?.message}
                  />
                </Grid>
                {inputNote?.updated_at && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="gray">
                      Ultima modifica: {formatDate(inputNote?.updated_at, 'dd/MM/yyyy HH:mm')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="inherit" variant="contained">
                Annulla
              </Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Salva
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      )}
    </>
  );
}
