import axios, { AxiosHeaders } from 'axios';
import { HOST_ENDPOINT_BASE_URL } from 'config';
import { formatError } from 'hooks/useComponentStatus';
import ApiCache from './apiCache';

// ----------------------------------------------------------------------

const apiCache = new ApiCache();

const dynamicAxios = (token?: string) => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.baseURL = HOST_ENDPOINT_BASE_URL;

  axiosInstance.interceptors.request.use((config) => {
    apiCache.handleRequest(config);
    const authToken = token || localStorage.getItem('accessToken');
    if (authToken && !!config.headers) {
      (config.headers as AxiosHeaders).set('Authorization', `Bearer ${authToken}`);
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => apiCache.handleResponse(response),
    (error) => Promise.reject(formatError(error) || 'Something went wrong')
  );
  return axiosInstance;
};

export default dynamicAxios;
