import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { createAccountService } from '@restworld/data-services';
import { HOST_ENDPOINT_BASE_URL } from 'config';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    isReady: boolean;
    jwt?: string;
    userRoleKeys?: string[];
  };
  [Types.Login]: {
    user: AuthUser;
    isReady: boolean;
    jwt: string;
    userRoleKeys?: string[];
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
    jwt: string;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isReady: false,
  user: null,
  userRoleKeys: []
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        isReady: true,
        jwt: action.payload.jwt,
        userRoleKeys: action.payload.userRoleKeys
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isReady: true,
        jwt: action.payload.jwt
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isReady: true
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isReady: true,
        jwt: action.payload.jwt
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await createAccountService({
            jwt: accessToken,
            baseUrl: HOST_ENDPOINT_BASE_URL
          }).me();
          const user = response.data;
          const role_keys = user?.data?.roles?.map((role: any) => role?.key);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              isReady: true,
              user,
              jwt: accessToken,
              userRoleKeys: role_keys
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              isReady: true,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        window.localStorage.removeItem('accessToken');
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            isReady: true,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    let response = await createAccountService({ baseUrl: HOST_ENDPOINT_BASE_URL }).login({
      email,
      password
    });
    const { jwt: accessToken, user_type } = response.data;
    if (user_type !== 'rw_member') {
      throw new Error('Invalid user type');
    }
    setSession(accessToken);

    let res = await createAccountService({
      baseUrl: HOST_ENDPOINT_BASE_URL,
      jwt: accessToken
    }).me();

    const user = res.data;

    dispatch({
      type: Types.Login,
      payload: {
        user,
        isReady: true,
        jwt: accessToken
      }
    });
  };

  // const register = async (email: string, password: string, firstName: string, lastName: string) => {
  //   const response = await axios.post('/api/account/register', {
  //     email,
  //     password,
  //     firstName,
  //     lastName
  //   });
  //   const { accessToken, user } = response.data;

  //   window.localStorage.setItem('accessToken', accessToken);
  //   dispatch({
  //     type: Types.Register,
  //     payload: {
  //       user,
  //       jwt: accessToken
  //     }
  //   });
  // };

  const register = (email: string, password: string, firstName: string, lastName: string) =>
    new Promise<void>(() => {});

  const logout = async () => {
    setSession(null);
    localStorage.removeItem('accessToken');
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => {
    /* */
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        userRoleKeys: state.userRoleKeys
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
