import {
  Paper,
  Stack,
  Typography,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { formatTime } from 'utils/common';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { EditItem } from './EditItem';
import { styled } from '@mui/material';

export function DisplayItem({
  index,
  deletePastExp
}: {
  index: number;
  deletePastExp: VoidFunction;
}) {
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);
  const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(null);

  const { getValues } = useFormContext();
  const pe = getValues(`past_experiences.${index}`);

  const handleEdit = () => {
    setIsEditMode(true);
    setIsActionMenuOpen(false);
  };

  const handleDelete = () => {
    deletePastExp();
    setIsActionMenuOpen(false);
  };

  return (
    <Paper
      elevation={12}
      sx={{
        p: theme.spacing(3),
        borderRadius: '16px',
        m: theme.spacing(3)
      }}
    >
      <EditItem isOpen={isEditMode} onClose={() => setIsEditMode(false)} index={index} />
      <ActionMenu
        isOpen={isActionMenuOpen}
        onClose={() => setIsActionMenuOpen(false)}
        anchorEl={actionMenuAnchor}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">{pe.occupation?.label_it.label}</Typography>
        <IconButton
          onClick={(e) => {
            setIsActionMenuOpen(true);
            setActionMenuAnchor(e.currentTarget);
          }}
        >
          <MoreVert fontSize="small" />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={1}>
        <LightText variant="body2">
          {!!pe.from_date ? formatTime({ dateTime: pe.from_date, noTime: true }) : 'N.D.'}
        </LightText>
        <LightText variant="body2">-</LightText>
        <LightText variant="body2">
          {!!pe.to_date ? formatTime({ dateTime: pe.to_date, noTime: true }) : 'N.D.'}
        </LightText>
      </Stack>
      <Stack direction="row" spacing={1}>
        <LightText variant="body2" sx={{ textTransform: 'capitalize' }}>
          {pe.restaurant_name || 'N.D.'}
        </LightText>
        <LightText variant="body2">-</LightText>
        <LightText variant="body2" sx={{ textTransform: 'capitalize' }}>
          {pe.city || 'N.D.'}
        </LightText>
      </Stack>
      <Typography>{pe.description || 'Nessuna descrizione'}</Typography>
    </Paper>
  );
}

function ActionMenu({
  isOpen,
  onClose,
  anchorEl,
  onEdit,
  onDelete
}: {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  onEdit: () => void;
  onDelete: () => void;
}) {
  return (
    <Menu open={isOpen} onClose={onClose} anchorEl={anchorEl}>
      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <ListItemText>Modifica</ListItemText>
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        <ListItemText>Elimina</ListItemText>
      </MenuItem>
    </Menu>
  );
}

const LightText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600]
}));
