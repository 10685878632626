import { useState, useEffect, useCallback } from 'react';
import { AsyncStatus, Status } from '../@types/componentLifecycle';
import { useSnackbar } from 'notistack';
import { timeoutOnSuccess, timeoutOnError } from 'constants/common';

export default function useComponentStatus(
  contexts: string[],
  options?: { [context: string]: { showNotificationOnSuccess?: boolean } }
) {
  const [status, setStatus] = useState<Status>();
  const { enqueueSnackbar } = useSnackbar();

  const initStatus = useCallback(() => {
    if (!contexts) return;
    let statusMap: Status = {};
    for (let context of contexts) {
      statusMap[context] = { status: 'IDLE' };
    }
    setStatus(statusMap);
  }, [contexts]);

  const updateStatus = useCallback(
    (context: string, newStatus: AsyncStatus, message?: string) => {
      // if (error) setStatus((prev) => ({ ...prev, [context]: { status: newStatus, error: error } }));
      // else setStatus((prev) => ({ ...prev, [context]: { status: newStatus } }));
      setStatus((prev) => ({ ...prev, [context]: { status: newStatus } }));

      if (newStatus === 'SUCCESS' || newStatus === 'ERROR') {
        let notificationMessage = message;

        if (
          newStatus === 'SUCCESS' &&
          options &&
          options[context] &&
          options[context].showNotificationOnSuccess
        ) {
          if (!notificationMessage) notificationMessage = 'Successo!';
          enqueueSnackbar(notificationMessage || '', {
            variant: 'success'
          });
        } else if (newStatus === 'ERROR') {
          if (!notificationMessage) notificationMessage = 'Qualcosa è andato storto :(';
          enqueueSnackbar(notificationMessage || '', { variant: 'error' });
        }
      }

      if (newStatus === 'SUCCESS') {
        setTimeout(
          () => setStatus((prev) => ({ ...prev, [context]: { status: 'IDLE' } })),
          timeoutOnSuccess
        );
      } else if (newStatus === 'ERROR') {
        setTimeout(
          () => setStatus((prev) => ({ ...prev, [context]: { status: 'IDLE' } })),
          timeoutOnError
        );
      }
    },
    [enqueueSnackbar, options]
  );

  useEffect(() => {
    initStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { status, updateStatus };
}

export const formatError = (error: any) => ({
  code: error.response.status,
  message: error.message,
  data: error.response?.data
});
