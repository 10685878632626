import * as React from 'react';
import { ListSubheader, MenuItem, Switch, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { StyledInputLabel } from 'pages/onboarding/JobPositionForm/components';
import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { Variable } from '@restworld/utility-types';

import { IRestaurantFormValues } from '../RestaurantForm.types';
import SelectFormInput from './SelectFormComponent';
import MultiSelectFormComponent from './MultiSelectFormComponent';
import TextFieldFormComponent from './TextFieldFormComponent';

interface IRestaurantDetailsFormProps {}

export const RestaurantDetailsForm: React.FunctionComponent<IRestaurantDetailsFormProps> = (
  props
) => {
  const {
    variableOptions,
    isLoading: isVariablesLoading,
    fetchVariable
  } = useGlobalOptionsFetchingContext();

  const { control, watch } = useFormContext<IRestaurantFormValues>();

  const watchIsStaffDistinguishable = watch('is_staff_distinguishable');

  React.useEffect(() => {
    fetchVariable([
      'covers_per_service',
      'covers_avg_price',
      'closing_days',
      'shifts',
      'restaurant_types',
      'environment_type',
      'client_type'
    ]);
  }, [fetchVariable]);

  const {
    covers_per_service,
    covers_avg_price,
    closing_days,
    shifts,
    restaurant_types,
    environment_type,
    client_type
  } = variableOptions;

  const categorizeRestaurantTypes = React.useMemo(() => {
    const newCategorizedType = restaurant_types?.reduce((prevValue, currValue) => {
      const parentLabel = currValue.parent_label ?? 'UnCategorized';

      if (!prevValue[parentLabel]) prevValue[parentLabel] = [];
      prevValue[parentLabel].push(currValue);

      return prevValue;
    }, {} as { [x: string]: Variable[] });
    return newCategorizedType;
  }, [restaurant_types]);

  return (
    <Grid item container rowGap={3}>
      <MultiSelectFormComponent
        formLabel="Che tipologia di locale è?"
        formId="types"
        options={restaurant_types?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
        renderMenuItems={
          categorizeRestaurantTypes
            ? Object.keys(categorizeRestaurantTypes).map((category) => {
                return [
                  <ListSubheader key={category}>{category}</ListSubheader>,
                  ...categorizeRestaurantTypes[category].map((type) => {
                    return (
                      <MenuItem key={type.id} value={type.id}>
                        {type.label}
                      </MenuItem>
                    );
                  })
                ];
              })
            : []
        }
        placeholder="Seleziona uno o più tipo di ristorante"
      />
      <TextFieldFormComponent
        formId="free_text_restaurant_type"
        formLabel="Specificare se altro"
        placeholder="Inserisci il tipo di ristorante personalizzato"
      />
      <TextFieldFormComponent
        formId="brief_description"
        formLabel="Breve descrizione del locale"
        placeholder="Inserisci una breve descrizione del locale"
        multiline
      />
      <TextFieldFormComponent
        formId="cuisine_note"
        formLabel="Note specifiche sulla cucina del locale"
        placeholder="Inserisci la nota sulla cucina"
        multiline
      />
      <SelectFormInput
        formLabel="Che tipologia di locale è?"
        formId="environment_type"
        options={environment_type?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
      />
      <MultiSelectFormComponent
        formLabel="Che tipologia di clienti avete?"
        formId="client_types"
        options={client_type?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
        placeholder="Seleziona uno o più tipologia di clienti"
      />
      <Grid item container alignItems="center">
        <StyledInputLabel htmlFor="is_staff_distinguishable">
          Lo staff è distinguibile?
        </StyledInputLabel>
        <Controller
          name="is_staff_distinguishable"
          control={control}
          render={({ field }) => <Switch checked={!!field.value} {...field} />}
        />
      </Grid>
      <TextFieldFormComponent
        formId="staff_size_floor"
        formLabel="Quante persone lavorano in sala? (Compreso banco, bar e cassa)"
        placeholder="Inserisci quante persone lavorano nella stanza"
        inputType="number"
        disabled={!watchIsStaffDistinguishable}
      />
      <TextFieldFormComponent
        formId="staff_size_kitchen"
        formLabel="Quante persone lavorano in cucina?"
        placeholder="Inserisci quante persone lavorano in cucina"
        inputType="number"
        disabled={!watchIsStaffDistinguishable}
      />
      <SelectFormInput
        formLabel="Quanti coperti o consegne fate a servizio?"
        formId="covers_per_service"
        options={covers_per_service?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
      />
      <SelectFormInput
        formLabel="Qual è il prezzo medio a coperto o consegna?"
        formId="covers_avg_price"
        options={covers_avg_price?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
      />
      <MultiSelectFormComponent
        formLabel="Giorni di chiusura"
        formId="closing_days"
        options={closing_days?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
        placeholder="Seleziona uno o più giorni di chiusura"
      />
      <MultiSelectFormComponent
        formLabel="Che turni fate?"
        formId="shifts"
        options={shifts?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
        placeholder="Seleziona uno o più che turni fate"
      />
    </Grid>
  );
};

export default RestaurantDetailsForm;
