import { ReactNode, useContext, cloneElement, ReactElement, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ArrowRight from '@mui/icons-material/ArrowForward';
import { SidebarContext } from 'contexts/SidebarContext';
import { Box, useTheme } from '@mui/material';
import { SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH } from 'config';

const MainContainer = styled('div')`
  position: relative;
`;

const DrawerContent = styled('div')(
  () => `
  padding: 16px 25px;
  `
);

export default function SidebarFilters({ children }: { children: ReactNode | ReactNode[] }) {
  const { sidebarStatus, handleToggle } = useContext(SidebarContext);
  const theme = useTheme();

  const handleOnSidebarToggle = useCallback(() => {
    handleToggle('filter', SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH);
  }, [handleToggle]);

  const isOpen = useMemo(
    () =>
      (sidebarStatus[SIDEBAR_FILTERS_POSITION].sidebarName === 'filter' &&
        sidebarStatus[SIDEBAR_FILTERS_POSITION].isOpen) ||
      false,
    [sidebarStatus]
  );

  return (
    <MainContainer>
      <Drawer
        // container={container}
        variant="persistent"
        anchor="right"
        open={isOpen ? true : false}
        onClose={handleOnSidebarToggle}
        PaperProps={{ sx: { minWidth: SIDEBAR_FILTERS_WIDTH, maxWidth: SIDEBAR_FILTERS_WIDTH } }}
        ModalProps={{ keepMounted: true }}
      >
        <DrawerContent>
          <Box sx={{ textAlign: 'right', mb: theme.spacing(2) }}>
            <IconButton color="primary" onClick={handleOnSidebarToggle}>
              <ArrowRight />
            </IconButton>
          </Box>
          {cloneElement(children as ReactElement, { isOpen })}
        </DrawerContent>
      </Drawer>
    </MainContainer>
  );
}
