import { useCallback, useEffect, useState } from 'react';
import {
  useCvParsingService,
  UnanalysedParsedCv,
  CollectParsedCvErrors
} from '@restworld/data-services';

export default function useCvParsingFeedback() {
  const cvParsingService = useCvParsingService();

  const [parsedCvInfo, setParsedCvInfo] = useState<UnanalysedParsedCv | null>(null);
  const [correctedCvCount, setCorrectedCvCount] = useState<number | null>(null);

  //Loading states
  const [isSkippingLoading, setIsSkippingLoading] = useState<boolean>(false);
  const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);

  const fetchUnanalysedCv = useCallback(() => {
    cvParsingService
      .getUnanalysedParsedCv()
      .then((res) => setParsedCvInfo(res.data))
      .catch((err) => console.error(err));
  }, [cvParsingService]);

  useEffect(() => {
    fetchUnanalysedCv();
  }, [fetchUnanalysedCv]);

  const getCurrentCount = useCallback(async () => {
    if (!parsedCvInfo) return;
    const res = await cvParsingService.getCorrectedCvCount();
    setCorrectedCvCount(res.data);
  }, [cvParsingService, parsedCvInfo]);

  useEffect(() => {
    getCurrentCount();
  }, [getCurrentCount]);

  const saveCorrections = useCallback(
    async (data: CollectParsedCvErrors) => {
      setIsSavingLoading(true);

      const res = await cvParsingService.collectErrors(data);
      await fetchUnanalysedCv();
      await getCurrentCount();

      !!res && setIsSavingLoading(false);
    },
    [cvParsingService, fetchUnanalysedCv, getCurrentCount]
  );

  const skipCv = useCallback(async () => {
    if (!parsedCvInfo?.cv_url) return;
    setIsSkippingLoading(true);

    const res = await cvParsingService.setParsedCvAsAnalysed(parsedCvInfo.cv_url);
    await fetchUnanalysedCv();

    !!res && setIsSkippingLoading(false);
  }, [cvParsingService, fetchUnanalysedCv, parsedCvInfo]);

  return {
    parsedCvInfo,
    saveCorrections,
    skipCv,
    isSkippingLoading,
    isSavingLoading,
    correctedCvCount
  };
}
