import { useSnackbar } from 'notistack';
import { DEFAULT_ACTION_DELAY } from 'config';
import { createContext, ReactNode } from 'react';

interface DelayFunctionProps {
  actionName: string;
  functionToExecute: () => void;
  waitTime?: number;
  onCancel?: VoidFunction;
}

const DelayFunctionContext = createContext<{
  delayFunction: ({ actionName, functionToExecute, onCancel }: DelayFunctionProps) => void;
}>({
  delayFunction: () => {}
});

function DelayFunctionProvider({ children }: { children: ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();

  const delayFunction = ({
    actionName,
    functionToExecute,
    waitTime: inputWaitTime,
    onCancel
  }: DelayFunctionProps) => {
    // msec
    const waitTime = !!inputWaitTime ? inputWaitTime : DEFAULT_ACTION_DELAY;
    enqueueSnackbar('', {
      variant: 'delayAction',
      waitTime,
      preventDuplicate: true,
      actionName: actionName,
      autoHideDuration: waitTime,
      functionToExecute,
      onCancel
    });
  };

  return (
    <DelayFunctionContext.Provider value={{ delayFunction }}>
      {children}
    </DelayFunctionContext.Provider>
  );
}

export { DelayFunctionContext, DelayFunctionProvider };
