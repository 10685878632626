import { Box, Stack, Typography } from '@mui/material';

interface Props {
  hit: any;
}

export default function OrganizationHit({ hit }: Props) {
  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1">{hit.name?.slice(0, 27) || '-'}</Typography>
      </Stack>
      {!!hit?.companies && (
        <Stack direction="column" spacing={1}>
          {hit?.companies?.map((company: any) => {
            return (
              <Typography key={company.id} variant="caption">
                {company.name} - {company.registered_office}
              </Typography>
            );
          })}
        </Stack>
      )}
      {!!hit?.employer_user_contacts && (
        <Stack direction="column" spacing={1}>
          {hit?.employer_user_contacts?.map((euc: any) => {
            return (
              <Typography
                key={euc.user_id}
                variant="caption"
                textTransform="uppercase"
                fontWeight="bold"
              >
                {euc?.user?.name} {euc?.user?.surname}
              </Typography>
            );
          })}
        </Stack>
      )}
    </Box>
  );
}
