import { Box, Grid, Typography, Stack, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { IRestaurantsPayload } from '@restworld/data-services';
import { useRestaurantsService, useEmployersService } from '@restworld/data-services';

import OrganizationDetails from './OrganizationDetails';
import RestaurantGlossaries from './RestaurantGlossaries';
import { useQueryParams } from './RestaurantForm.utils';
import { defaultPayloadFromData, restaurantFormCategories } from './RestaurantForm.constants';
import { LocalInformationForm, RestaurantDetailsForm, ReferDetailsForm } from './forms-components';
import { IRestaurantFormValues } from './RestaurantForm.types';

interface IRestaurantFormProps {}

const RestaurantForm: React.FunctionComponent<IRestaurantFormProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getQueryParams = useQueryParams();
  const restaurantId = getQueryParams('restaurantId');
  const restaurantService = useRestaurantsService();
  const employerService = useEmployersService();

  const mainTitle = useMemo(
    () => (restaurantId ? 'Aggiorna ristorante' : 'Registra un nuovo locale'),
    [restaurantId]
  );

  const { data: restaurantData, isFetching } = useQuery({
    queryKey: ['restaurant'],
    queryFn: async () => (await restaurantService.showRestaurant(restaurantId)).data,
    enabled: !!restaurantId,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false
  });

  const organizationId = useMemo(() => {
    const orgId = getQueryParams('organizationId');
    if (!orgId) return restaurantData?.restaurant?.organization_id ?? '';
    return orgId;
  }, [getQueryParams, restaurantData?.restaurant?.organization_id]);

  useQuery({
    queryKey: ['employer_contacts'],
    queryFn: async () =>
      (await employerService.getEmployerContacts(organizationId)).data.employer_contacts,
    enabled: !!organizationId,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false
  });
  const methods = useForm<IRestaurantFormValues>({
    defaultValues: { ...defaultPayloadFromData }
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    const restaurantDataZ = restaurantData?.restaurant;
    if (restaurantId && restaurantDataZ) {
      const formData = {
        ...restaurantDataZ,
        client_types: restaurantDataZ?.client_types
          ? restaurantDataZ.client_types?.map(({ client_type_var }) => client_type_var.id)
          : [],
        closing_days: restaurantDataZ?.closing_days
          ? restaurantDataZ.closing_days?.map(({ closing_day_var }) => closing_day_var.id)
          : [],
        shifts: restaurantDataZ?.shifts
          ? restaurantDataZ.shifts?.map(({ shift_var }) => shift_var.id)
          : [],
        types: restaurantDataZ?.types
          ? restaurantDataZ.types?.map(({ type_var }) => type_var.id)
          : [],
        referent_user_contacts: restaurantDataZ?.referent_user_contacts
          ? restaurantDataZ?.referent_user_contacts.map((v) => v?.user_id)
          : [],
        ...(restaurantDataZ?.environment_type_var?.id
          ? { environment_type: restaurantDataZ?.environment_type_var?.id }
          : {}),
        ...(restaurantDataZ?.management_type_var?.id
          ? { management_type: restaurantDataZ?.management_type_var?.id }
          : {}),
        ...(restaurantDataZ?.covers_avg_price_var?.id
          ? {
              covers_avg_price: restaurantDataZ?.covers_avg_price_var?.id
            }
          : {}),
        ...(restaurantDataZ?.covers_per_service_var?.id
          ? {
              covers_per_service: restaurantDataZ?.covers_per_service_var?.id
            }
          : {}),
        ...(restaurantDataZ?.future_goal_var?.id
          ? {
              future_goal: restaurantDataZ?.future_goal_var?.id
            }
          : {})
      };
      reset(formData);
    }
  }, [reset, restaurantData?.restaurant, restaurantId]);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<IRestaurantFormValues> = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const payload = {
          ...values,
          ...(values.client_types
            ? {
                client_types: values.client_types?.map((clientType) => ({
                  client_type: clientType
                }))
              }
            : []),
          closing_days: values.closing_days?.map((closingDay) => ({ closing_day: closingDay })),
          shifts: values.shifts?.map((shift) => ({ shift: shift })),
          types: values.types?.map((type) => ({ type })),
          organization_id: organizationId,
          ...(values.is_staff_distinguishable
            ? {
                staff_size_floor: values.staff_size_floor,
                staff_size_kitchen: values.staff_size_kitchen
              }
            : {
                staff_size_floor: null,
                staff_size_kitchen: null
              })
        } as IRestaurantsPayload;

        const data = restaurantId
          ? (await restaurantService.updateRestaurant({ restaurantId, params: { ...payload } }))
              .data
          : (await restaurantService.createRestaurant(payload)).data;

        const redirectUser = () => {
          enqueueSnackbar(
            `Ristorante ${
              restaurantId ? 'aggiornato' : 'creato'
            } con successo, tornando alla pagina del ristorante...`,
            {
              variant: 'success'
            }
          );
          setTimeout(() => {
            navigate(`/restaurants/${restaurantId ? restaurantId : (data as any)?.id}`);
          }, 1500);
        };
        redirectUser();
      } catch (error) {
        console.error(`Error creating restaurant`);
        enqueueSnackbar(`Errore durante la creazione del ristorante`);
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, navigate, organizationId, restaurantId, restaurantService]
  );
  if (isFetching) return <CircularProgress size={20} />;
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ paddingLeft: '42%', paddingRight: '10%' }}>
        <Box sx={{ position: 'fixed', left: '5%', maxHeight: '100vh', overflowY: 'auto' }}>
          <Stack spacing={3}>
            <OrganizationDetails />
            <RestaurantGlossaries formCategories={restaurantFormCategories} />
          </Stack>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <Typography variant="h4" textAlign="center">
              {mainTitle}
            </Typography>
          </Grid>
          <FormProvider {...methods}>
            <form
              action=""
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            >
              <Grid container gap={6}>
                {restaurantFormCategories.map((formCategory) => {
                  return (
                    <Grid
                      item
                      container
                      rowGap={3}
                      key={formCategory.id}
                      id={formCategory.id}
                      data-section
                    >
                      <Grid item xs={12}>
                        <Typography variant="h4">{formCategory.label}</Typography>
                      </Grid>
                      {formCategory.id === 'local_information' ? (
                        <LocalInformationForm />
                      ) : formCategory.id === 'restaurant_details' ? (
                        <RestaurantDetailsForm />
                      ) : (
                        <ReferDetailsForm />
                      )}
                    </Grid>
                  );
                })}
                <Grid item container xs={12} justifyContent="center" gap={2} sx={{ mt: 2 }}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    type="submit"
                  >
                    {!restaurantId ? 'Salva' : 'Aggiornamento'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default RestaurantForm;
