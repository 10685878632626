import { Grid } from '@mui/material';
import * as React from 'react';

import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import AddressInputForm from './AddressInputForm';
import SelectFormInput from './SelectFormComponent';
import TextFieldFormComponent from './TextFieldFormComponent';

interface ILocalInformationFormProps {}

export const LocalInformationForm: React.FunctionComponent<ILocalInformationFormProps> = (
  props
) => {
  const {
    variableOptions,
    isLoading: isVariablesLoading,
    fetchVariable
  } = useGlobalOptionsFetchingContext();

  React.useEffect(() => {
    fetchVariable(['management_type']);
  }, [fetchVariable]);

  const { management_type } = variableOptions;

  return (
    <Grid item container rowGap={3}>
      <TextFieldFormComponent
        formId="restaurant_name"
        formLabel="Come si chiama il locale?"
        placeholder="Inserisci il nome del locale"
        required
      />
      <AddressInputForm
        formId="business_address"
        formLabel="Cerca il locale su Google Maps"
        required
      />
      <AddressInputForm
        formId="address"
        formLabel="Indirizzo del locale"
        labelInfoText="Questo è l'indirizzo mostrato ai candidati negli annunci di lavoro"
        required
      />
      <Grid item container justifyContent="space-between" spacing={1}>
        <Grid item xs={4}>
          <TextFieldFormComponent
            formId="administrative_area_level_1"
            formLabel="Regione"
            placeholder="Inserisci la regione"
            isContainer={false}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormComponent
            formId="locality"
            formLabel="Città"
            placeholder="Inserisci la città"
            isContainer={false}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormComponent
            formId="postal_code"
            formLabel="Codice postale"
            placeholder="Inserisci il codice postale"
            isContainer={false}
          />
        </Grid>
      </Grid>
      <TextFieldFormComponent
        formId="location_description"
        formLabel="Breve descrizione della location"
        placeholder="Inserisci una breve descrizione della location"
        multiline
      />
      <SelectFormInput
        formLabel="Chi decide quali persone assumere?"
        formId="management_type"
        options={management_type?.map((v) => ({ value: v.id, label: v.label }))}
        loading={isVariablesLoading}
      />
    </Grid>
  );
};

export default LocalInformationForm;
