import { useState, useEffect } from 'react';
import {
  RestaurantClient,
  Address as RestaurantAddress,
  RestaurantClosingDay,
  RestaurantShift,
  RestaurantType as RestaurantTypeType,
  Variable,
  Restaurant as RestaurantType
} from '@restworld/utility-types';
import {
  Grid,
  Stack,
  Box,
  TextField,
  Autocomplete,
  Typography,
  Button,
  Tooltip,
  Divider,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useGlobalOptionsFetchingContext from 'hooks/useGlobalOptionsFetchingContext';
import { styled } from '@mui/material/styles';
import CropLongText from 'components/CropLongText';
import AddressInput from './AddressInput';
import { InfoOutlined } from '@mui/icons-material';

export const DetailsItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  whiteSpace: 'pre-wrap'
}));

const detailsItemStyle = {
  color: 'grey.600'
};

export default function RestaurantDetailView({
  inputRestaurant,
  handleUpdateRestaurant,
  onClose,
  isLoading,
  isEditMode
}: {
  isLoading?: boolean;
  onClose: () => void;
  inputRestaurant: RestaurantType;
  handleUpdateRestaurant: (restarant: RestaurantType) => void;
  isEditMode: boolean;
}) {
  const [restaurant, setRestaurant] = useState<RestaurantType>(inputRestaurant);

  const [restaurantErrors, setRestaurantErrors] = useState<{
    field: 'address' | 'referent_contact' | undefined;
    error: boolean;
    text: string;
  }>();

  const {
    variableOptions,
    isLoading: isVariablesLoading,
    fetchVariable
  } = useGlobalOptionsFetchingContext();

  const {
    restaurant_type,
    covers_per_service,
    covers_avg_price,
    management_type,
    client_type,
    closing_days,
    shifts
  } = variableOptions;

  useEffect(() => setRestaurant(inputRestaurant), [inputRestaurant]);

  useEffect(() => {
    if (isEditMode)
      fetchVariable([
        'restaurant_type',
        'covers_per_service',
        'covers_avg_price',
        'management_type',
        'client_type',
        'closing_days',
        'shifts'
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const updateRestaurant = (
    key: keyof RestaurantType,
    value: any,
    addressInputs?: RestaurantAddress
  ) => {
    if (key === 'business_address' || key === 'address') {
      setRestaurant((prev) => ({
        ...prev,
        [key]: value,
        address_lat: addressInputs?.address_lat!,
        address_lon: addressInputs?.address_lon!,
        locality: addressInputs?.locality!,
        country: addressInputs?.country!,
        postal_code: addressInputs?.postal_code!,
        administrative_area_level_1: addressInputs?.administrative_area_level_1!,
        administrative_area_level_2: addressInputs?.administrative_area_level_2!,
        gmaps_place_id: addressInputs?.gmaps_place_id!
      }));
    } else {
      setRestaurant((prev) => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const isPayloadValid = (): boolean => {
    if (
      !!!restaurant.address ||
      restaurant.address === '' ||
      (!!!restaurant.address_lat && !!!restaurant.address_lon)
    ) {
      setRestaurantErrors({
        field: 'address',
        text: "L'indirizzo non può essere vuoto",
        error: true
      });
      return false;
    }
    return true;
  };

  const handleInternalUpdate = () => {
    if (isPayloadValid()) {
      handleUpdateRestaurant(restaurant);
      resetErrors();
    }
  };

  const resetErrors = () => {
    setRestaurantErrors({ field: undefined, error: false, text: '' });
  };

  const handleClose = () => {
    setRestaurant(inputRestaurant);
    resetErrors();
    onClose();
  };

  return (
    <>
      {isEditMode ? (
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Stack spacing={2}>
              <AddressInput
                errors={restaurantErrors}
                address={restaurant?.address || ''}
                autoCompleteTypes={[]}
                updateRestaurant={updateRestaurant}
                label="Indirizzo"
                restaurantKey="address"
              />
              <AddressInput
                errors={restaurantErrors}
                address={restaurant?.business_address || ''}
                updateRestaurant={updateRestaurant}
                autoCompleteTypes={['establishment']}
                label="Indirizzo sul lavoro"
                restaurantKey="business_address"
              />
              <TextField
                type="text"
                value={restaurant?.locality || ''}
                onChange={(e) => updateRestaurant('locality', e.target.value)}
                variant="outlined"
                label="Città"
              />
              <Stack alignItems="flex-start">
                <FormControlLabel
                  control={
                    <Switch
                      id="show_details"
                      checked={Boolean(restaurant?.show_details)}
                      hidden={isEditMode}
                      onChange={(e) => updateRestaurant('show_details', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={<Typography variant="subtitle2">Visibile pubblicamente</Typography>}
                  labelPlacement="start"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack spacing={2}>
              <Autocomplete
                multiple
                loading={isVariablesLoading}
                options={restaurant_type || []}
                value={restaurant.types?.map((type: RestaurantTypeType) => type.type_var)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: Variable) => option.label}
                onChange={(event, newValue: Array<Variable>) =>
                  updateRestaurant(
                    'types',
                    newValue.map((item: Variable) => ({
                      type_var: item
                    }))
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tipologia" />
                )}
              />
              <Autocomplete
                options={covers_per_service || []}
                loading={isVariablesLoading}
                getOptionLabel={(option: Variable) => option.label}
                onChange={(event, newValue) => {
                  updateRestaurant('covers_per_service', newValue?.id || null);
                  updateRestaurant('covers_per_service_var', newValue);
                }}
                value={restaurant.covers_per_service_var || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Coperti" />
                )}
              />
              <TextField
                type="number"
                value={restaurant.staff_size_floor || ''}
                onChange={(e) => updateRestaurant('staff_size_floor', e.target.value)}
                variant="outlined"
                label="Staff sala"
              />
              <TextField
                type="number"
                value={restaurant.staff_size_kitchen || ''}
                onChange={(e) => updateRestaurant('staff_size_kitchen', e.target.value)}
                variant="outlined"
                label="Staff cucina"
              />
              <Autocomplete
                options={covers_avg_price || []}
                loading={isVariablesLoading}
                getOptionLabel={(option: Variable) => option.label}
                onChange={(event, newValue) => {
                  updateRestaurant('covers_avg_price', newValue?.id || null);
                  updateRestaurant('covers_avg_price_var', newValue);
                }}
                value={restaurant.covers_avg_price_var || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Prezzo" />
                )}
              />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack spacing={2}>
              <Autocomplete
                options={management_type || []}
                loading={isVariablesLoading}
                getOptionLabel={(option: Variable) => option.label}
                onChange={(event, newValue) => {
                  updateRestaurant('management_type', newValue?.id || null);
                  updateRestaurant('management_type_var', newValue);
                }}
                value={restaurant.management_type_var || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Conduzione" />
                )}
              />
              <Autocomplete
                multiple
                options={client_type || []}
                loading={isVariablesLoading}
                getOptionLabel={(option: Variable) => option.label}
                onChange={(event, newValue: Array<Variable>) =>
                  updateRestaurant(
                    'client_types',
                    newValue.map((item: Variable) => ({ client_type_var: item }))
                  )
                }
                value={restaurant.client_types?.map(
                  (client_type: RestaurantClient) => client_type.client_type_var
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Clienti" />
                )}
              />
              <Autocomplete
                multiple
                options={closing_days || []}
                loading={isVariablesLoading}
                getOptionLabel={(option: Variable) => option.label}
                onChange={(event, newValue: Array<Variable>) =>
                  updateRestaurant(
                    'closing_days',
                    newValue.map((item: Variable) => ({
                      closing_day: item.id,
                      closing_day_var: item
                    }))
                  )
                }
                value={restaurant.closing_days?.map(
                  (day: RestaurantClosingDay) => day.closing_day_var
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Chiusura" />
                )}
              />
              <Autocomplete
                multiple
                options={shifts || []}
                loading={isVariablesLoading}
                getOptionLabel={(option: Variable) => option.label}
                onChange={(event, newValue: Array<Variable>) =>
                  updateRestaurant(
                    'shifts',
                    newValue.map((item: Variable) => ({ shift_var: item }))
                  )
                }
                value={restaurant.shifts?.map((shift: RestaurantShift) => shift.shift_var)}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Orari" />}
              />
            </Stack>
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              label="Breve descrizione della location"
              value={restaurant.location_description}
              onChange={(e) => updateRestaurant('location_description', e.target.value)}
              variant="outlined"
              multiline
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              label="Breve descrizione del locale"
              value={restaurant.brief_description}
              onChange={(e) => updateRestaurant('brief_description', e.target.value)}
              variant="outlined"
              multiline
            />
          </Grid>
          <Grid item md={12}>
            <Stack direction="row" spacing={1} justifyContent="end">
              <Button variant="contained" color="inherit" onClick={handleClose}>
                Annulla
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleInternalUpdate}
                loading={isLoading}
              >
                Salva
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="subtitle1">Indirizzo</Typography>
                <Tooltip title="Questo indirizzo è visibile per i ristoranti nella pagina delle posizioni lavorative">
                  <InfoOutlined />
                </Tooltip>
              </Stack>
              <DetailsItem>{restaurant.address}</DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="subtitle1">Indirizzo sul lavoro</Typography>
                <Tooltip title="Questo indirizzo è utile per le informazioni di Google Maps">
                  <InfoOutlined />
                </Tooltip>
              </Stack>
              <DetailsItem>{restaurant?.business_address}</DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Città</Typography>
              <DetailsItem>{restaurant.locality}</DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Visibile pubblicamente</Typography>
              <DetailsItem>{restaurant?.show_details ? 'Sì' : 'No'}</DetailsItem>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Tipologia</Typography>
              <CropLongText
                labels={restaurant.types?.map((t) => t.type_var.label)}
                visibleLength={24}
                sx={detailsItemStyle}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Coperti</Typography>
              <DetailsItem>{restaurant.covers_per_service_var?.label}</DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Staff sala</Typography>
              <DetailsItem>{restaurant.staff_size_floor}</DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Staff cucina</Typography>
              <DetailsItem>{restaurant.staff_size_kitchen}</DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Prezzo</Typography>
              <DetailsItem>{restaurant.covers_avg_price_var?.label}</DetailsItem>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Conduzione</Typography>
              <DetailsItem>{restaurant.management_type_var?.label}</DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Clienti</Typography>
              <CropLongText
                labels={restaurant.client_types?.map((ct) => ct.client_type_var.label)}
                visibleLength={24}
                sx={detailsItemStyle}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Chiusura</Typography>
              <DetailsItem>
                {restaurant.closing_days?.map((cd) => cd.closing_day_var.label).join(', ')}
              </DetailsItem>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Orari</Typography>
              <CropLongText
                labels={restaurant.shifts?.map((s) => s.shift_var.label)}
                visibleLength={24}
                sx={detailsItemStyle}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Divider />
          </Grid>
          <Grid item md={6}>
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="subtitle1">Breve descrizione della location</Typography>
                <Tooltip title="Dettaglio per i candidati su dove è situato il locale. Vicino alla stazione del treno, comodo alla tangenziale, vicino a un grosso centro abitato, etc.">
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Stack>
              <DetailsItem>{restaurant.location_description}</DetailsItem>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="subtitle1">Breve descrizione del locale</Typography>
                <Tooltip title="Breve descrizione del locale da pubblicare sulle offerte di lavoro. Si può chiedere direttamente all'employer o al referente.">
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Stack>
              <DetailsItem>{restaurant.brief_description}</DetailsItem>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
