import { Refresh } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Menu } from '@mui/material';
import { CategoryPrompts } from 'hooks/useJobPositionTextGeneration';

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handlePromptReset: () => void;
  category: keyof CategoryPrompts;
}

export default function GenerationChatActionMenu({
  anchorEl,
  handleClose,
  handlePromptReset,
  category
}: Props) {
  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem onClick={handlePromptReset} disabled={category === 'csm_insights'}>
        <ListItemIcon>
          <Refresh sx={{ color: 'grey.600' }} />
        </ListItemIcon>
        <ListItemText>Resetta la chat</ListItemText>
      </MenuItem>
    </Menu>
  );
}
