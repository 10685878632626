import { Done } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { GenerationStatus, Status } from 'hooks/useJobPositionTextGeneration';

const CategoryStatusIcon = ({ status }: { status: Status }) => {
  let icon = null;
  switch (status) {
    case 'FINISHED':
    case 'SKIPPING':
      icon = <Done color="success" />;
      break;
    case 'IDLE':
    case 'LOADING':
      icon = <CircularProgress size="1.3rem" />;
      break;
  }
  return <ListItemIcon>{icon}</ListItemIcon>;
};

interface Props {
  status: GenerationStatus;
  isAnyLoading: boolean;
  isOpen: boolean;
}
export function JobPositionTextGenerationStatus({ status, isAnyLoading, isOpen }: Props) {
  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle>Generazione contenuti</DialogTitle>
      <DialogContent>
        <List>
          <ListItem sx={{ gap: 2 }}>
            <ListItemText>Titolo dell'annuncio</ListItemText>
            <CategoryStatusIcon status={status.job_title} />
          </ListItem>
          <ListItem sx={{ gap: 2 }}>
            <ListItemText>Descrizione dell'annuncio</ListItemText>
            <CategoryStatusIcon status={status.job_description} />
          </ListItem>
          <ListItem sx={{ gap: 2 }}>
            <ListItemText>Descrizione del contratto</ListItemText>
            <CategoryStatusIcon status={status.job_contract_description} />
          </ListItem>
        </List>
        <Alert severity={isAnyLoading ? 'info' : 'success'} sx={{ mt: 2 }}>
          <AlertTitle>
            {isAnyLoading
              ? 'Stiamo generando i primi contenuti per la posizione lavorativa'
              : 'Abbiamo finito la generazione!'}
          </AlertTitle>
          {isAnyLoading
            ? "Ci potrebbe volere qualche minuto per generare i primi contenuti per l'annuncio. Non ti preoccupare, dalla prossima modifica non dovrai più aspettare tutto questo tempo."
            : 'Adesso potrai revisionare e modificare i testi generati per te'}
        </Alert>
      </DialogContent>
    </Dialog>
  );
}
