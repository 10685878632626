import { useContext, useEffect } from 'react';
import { SidebarContext } from 'contexts/SidebarContext';
import { Dialog as BaseDialog, DialogProps } from '@mui/material';

export default function Dialog({ children, ...rest }: DialogProps) {
  const { setIsFABHidden } = useContext(SidebarContext);

  const { open } = rest;

  useEffect(() => {
    if (open) {
      setIsFABHidden(true);
    } else {
      setIsFABHidden(false);
    }

    return () => setIsFABHidden(false);
  }, [open, setIsFABHidden]);

  return <BaseDialog {...rest}>{children}</BaseDialog>;
}
