import { Assignment as AssignmentIcon } from '@mui/icons-material';
import { Badge, Box, Divider, IconButton, IconButtonProps, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MenuPopover from './MenuPopover';
import { useRwMembersService } from '@restworld/data-services';
import useAuth from 'hooks/useAuth';
import { JobPositionTask, RWMemberRole } from '@restworld/utility-types';
import { formatISO } from 'date-fns';
import { formatTime } from 'utils/common';
import JobPositionTaskDetail from './JobPositionTaskDetail';

interface Props extends IconButtonProps {}
export default function TaskManagerLite({ ...rest }: Props) {
  const rwMemberService = useRwMembersService();
  const { user } = useAuth();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [tasks, setTasks] = useState<JobPositionTask[] | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [taskDetail, setTaskDetail] = useState<{ isOpen: boolean; task?: JobPositionTask }>({
    isOpen: false
  });

  const isCsm = useMemo(
    () => user?.data.roles?.map((r: RWMemberRole) => r.key).includes('csm'),
    [user?.data]
  );

  const fetchTodayTasks = useCallback(() => {
    if (!isCsm) return;
    rwMemberService
      .getAgenda({ day: formatISO(new Date(), { representation: 'date' }) })
      .then((res) => {
        setTasks(
          (res.data as JobPositionTask[]).filter(
            (task: JobPositionTask) => !task.completed
          ) as JobPositionTask[]
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, [isCsm, rwMemberService]);

  useEffect(() => {
    isPopoverOpen && fetchTodayTasks();
  }, [fetchTodayTasks, isPopoverOpen]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchTodayTasks(), []);

  const handleOpen = () => setIsPopoverOpen(true);
  const handleClose = () => setIsPopoverOpen(false);
  const handleCloseTaskDetail = () => setTaskDetail({ isOpen: false });

  if (!isCsm) return null;

  return (
    <>
      <IconButton {...rest} onClick={handleOpen} ref={anchorRef}>
        <Badge badgeContent={tasks?.length} color="warning">
          <AssignmentIcon />
        </Badge>
      </IconButton>
      <JobPositionTaskDetail
        isOpen={taskDetail.isOpen}
        onClose={handleCloseTaskDetail}
        task={taskDetail.task}
      />
      <MenuPopover
        open={isPopoverOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
        rootSx={{ zIndex: 1199 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            Task
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {!!tasks && `Hai ${tasks.length} task da fare`}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Stack spacing={2} sx={{ pb: 3 }}>
          {!!tasks &&
            tasks?.map((task) => (
              <Box
                key={task.id}
                onClick={() => setTaskDetail({ isOpen: true, task })}
                sx={{ px: 3, py: 1, cursor: 'pointer', '&:hover': { bgcolor: 'grey.100' } }}
              >
                <Typography variant="subtitle2">
                  {task.category_var?.label} - {task.sub_category_var?.label}
                </Typography>
                <Typography variant="body2">{task.description}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {task.job_position?.occupation?.label_it.label},{' '}
                  {task.job_position?.restaurant.restaurant_name}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {formatTime({ dateTime: task.due_date, noTime: true })}
                </Typography>
              </Box>
            ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
