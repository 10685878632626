import { Hit as AlgoliaHit } from '@algolia/client-search';
import { List, ListItem } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import useAlgoliaSearch from 'hooks/useAlgoliaSearch';
import { useHits, UseHitsProps } from 'react-instantsearch-hooks';
import { Link } from 'react-router-dom';

export type HitsProps<THit> = React.ComponentProps<'div'> &
  UseHitsProps & {
    hitComponent: (props: { hit: THit }) => JSX.Element;
    redirectTo?: string;
    toggleOnClick?: boolean;
  };

export default function Hits<THit extends AlgoliaHit<Record<string, unknown>>>({
  hitComponent: Hit,
  redirectTo,
  toggleOnClick = true
}: HitsProps<THit>) {
  const { hits } = useHits();
  const { handleToggle } = useAlgoliaSearch();

  return (
    <Scrollbar>
      <List>
        {hits?.map((hit) => (
          <ListItem
            sx={{
              '&:hover': {
                bgcolor: 'grey.100'
              },
              color: 'inherit'
            }}
            component={!!redirectTo ? Link : ListItem}
            to={!!redirectTo ? `${redirectTo}/${hit.objectID}` : undefined}
            key={hit.objectID}
            onClick={toggleOnClick ? handleToggle : undefined}
          >
            <Hit hit={hit as unknown as THit} />
          </ListItem>
        ))}
      </List>
    </Scrollbar>
  );
}
