import { ReactNode, createContext, useState, useEffect, useMemo, useCallback } from 'react';
// material
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

type SidebarPosition = 'right' | 'left';
type SidebarStatus = {
  isOpen: boolean;
  sidebarName: string;
  width: string;
};
type SidebarCollapse = {
  left: SidebarStatus;
  right: SidebarStatus;
};

export type SidebarFiltersContextProps = {
  sidebarStatus: SidebarCollapse;
  handleToggle: (sidebarName: string, position: SidebarPosition, width: string) => void;
  contentWidth: string;
  reset: VoidFunction;
  isFABHidden: boolean;
  setIsFABHidden: (isHidden: boolean) => void;
};

const initialState: SidebarFiltersContextProps = {
  sidebarStatus: {
    right: { isOpen: false, sidebarName: '', width: '0' },
    left: { isOpen: false, sidebarName: '', width: '0' }
  },
  handleToggle: (name, position, width) => {},
  contentWidth: '100%',
  reset: () => {},
  isFABHidden: false,
  setIsFABHidden: (isHidden) => {}
};

const SidebarContext = createContext(initialState);

type SidebarFiltersProviderProps = {
  children: ReactNode;
};

function SidebarFiltersProvider({ children }: SidebarFiltersProviderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isFABHidden, setIsFABHidden] = useState<boolean>(false);
  const [sidebarStatus, setSidebarStatus] = useState<SidebarCollapse>(initialState.sidebarStatus);

  const contentWidth = useMemo(() => {
    const leftWidth = sidebarStatus.left.width;
    const rightWidth = sidebarStatus.right.width;

    if (rightWidth === '0' && leftWidth === '0') return '100%';
    if (rightWidth !== '0' && leftWidth === '0') return `calc(100% - ${rightWidth})`;
    if (rightWidth === '0' && leftWidth !== '0') return `calc(100% - ${leftWidth})`;
    if (rightWidth !== '0' && leftWidth !== '0') return `calc(100% - ${leftWidth} - ${rightWidth})`;
  }, [sidebarStatus.left.width, sidebarStatus.right.width]);

  useEffect(() => {
    if (isMobile) {
      setSidebarStatus(initialState.sidebarStatus);
    }
  }, [isMobile]);

  const handleToggleCollapse = useCallback(
    (sidebarName: string, position: SidebarPosition, width: string) => {
      setSidebarStatus((prev) => ({
        ...prev,
        [position]: {
          isOpen:
            prev[position].sidebarName !== sidebarName ? prev[position] : !prev[position].isOpen,
          sidebarName,
          width: prev[position].isOpen && prev[position].sidebarName === sidebarName ? '0' : width
        }
      }));
    },
    []
  );

  const reset = useCallback(() => {
    setSidebarStatus(initialState.sidebarStatus);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        sidebarStatus,
        handleToggle: handleToggleCollapse,
        contentWidth: contentWidth || '100%',
        reset,
        isFABHidden,
        setIsFABHidden
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarFiltersProvider, SidebarContext };
