import { forwardRef, useState, useEffect } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { Box, Card, CardActions, useTheme } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { DEFAULT_ACTION_DELAY } from 'config';

export type Props = {
  waitTime: number;
  functionToExecute: VoidFunction;
  actionName: string;
  onCancel?: VoidFunction;
};

const DelayAction = forwardRef<HTMLDivElement, Props & CustomContentProps>(
  ({ waitTime, functionToExecute, onCancel, id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const theme = useTheme();

    const [timer, setTimer] = useState<number>(DEFAULT_ACTION_DELAY);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

    const handleCancelAction = () => {
      !!timeoutId && clearTimeout(timeoutId);
      !!intervalId && clearInterval(intervalId);
      closeSnackbar(id);
      onCancel && onCancel();
    };

    useEffect(() => {
      if (timer <= 0) {
        clearInterval(intervalId);
      }
    }, [timer, intervalId]);

    useEffect(() => {
      let interval_id: any, timeout_id: any;

      if (waitTime > 0) {
        setTimer(waitTime);

        interval_id = setInterval(() => {
          setTimer((prev) => prev - 100);
        }, 100);
        setIntervalId(interval_id);

        timeout_id = setTimeout(() => {
          functionToExecute();
          clearInterval(interval_id);
          closeSnackbar(id);
        }, waitTime + 500);
        setTimeoutId(timeout_id);
      }

      return () => {
        clearInterval(interval_id);
        clearTimeout(timeout_id);
      };
    }, [functionToExecute, waitTime, id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref}>
        <Card sx={{ backgroundColor: theme.palette.warning.lighter }}>
          <CardActions>
            <div>
              Annulla <i>{props.actionName}</i>
            </div>
            <IconButton onClick={handleCancelAction} size="small" sx={{ ml: 1 }}>
              <Box sx={{ m: 1, position: 'relative' }}>
                <CloseIcon />
                <CircularProgress
                  value={(timer / waitTime) * 100}
                  variant="determinate"
                  sx={{
                    position: 'absolute',
                    top: -7,
                    left: -7,
                    zIndex: 1
                  }}
                  color="primary"
                />
              </Box>
            </IconButton>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

DelayAction.displayName = 'DelayAction';

export default DelayAction;
