import { timeoutOnError } from 'constants/common';
import useComponentStatus from 'hooks/useComponentStatus';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalsService } from '@restworld/data-services';
import { Status, Variable } from '@restworld/utility-types';
interface GlobalContextProps {
  fetchVariable: (variableName: string[]) => void;
  fetchStatus: (prefix: string[]) => void;
  statusOptions: { [prefix: string]: Status[] };
  variableOptions: { [variableName: string]: Variable[] };
  isLoading: boolean;
}

const initialState: GlobalContextProps = {
  fetchVariable: (value) => {},
  fetchStatus: (prefix) => {},
  statusOptions: {},
  variableOptions: {},
  isLoading: false
};

const GlobalOptionsFetchingContext = createContext(initialState);

type GlobalProviderProps = {
  children: ReactNode;
};

function GlobalOptionsFetchingProvider({ children }: GlobalProviderProps) {
  const { status: componentStatus, updateStatus } = useComponentStatus(['variable', 'status']);
  const globalService = useGlobalsService();

  const [variableOptions, setVariableOptions] = useState<{ [variableName: string]: Variable[] }>(
    {}
  );
  const [statusOptions, setStatusOptions] = useState<{ [prefix: string]: Status[] }>({});

  const [pendingVariableNames, setPendingVariableNames] = useState<string[]>([]);
  const [pendingStatusPrefix, setPendingStatusPrefix] = useState<string[]>([]);

  const _fetchVariable = useCallback(
    (variableNames: string[]) => {
      const context = 'variable';
      updateStatus(context, 'LOADING');
      Promise.all(
        variableNames.map((variableName) => globalService.fetchVariableOptions(variableName))
      )
        .then((res) => {
          setTimeout(() => updateStatus(context, 'IDLE'), 100);
          setVariableOptions((prev) => {
            let newVars: { [key: string]: Variable[] } = {};
            res.forEach((r, index) => {
              newVars[variableNames[index]] = r.data;
            });
            return {
              ...prev,
              ...newVars
            };
          });
        })
        .catch((err) => {
          updateStatus(context, 'ERROR', err);
          setTimeout(() => updateStatus(context, 'IDLE'), timeoutOnError);
        });
    },
    [globalService, updateStatus]
  );

  const fetchVariable = useCallback(
    (variableName: string[]) => {
      let needUpdate: boolean = false;
      variableName.forEach((v) => {
        if (!pendingVariableNames.includes(v)) needUpdate = true;
      });
      if (!needUpdate) return;
      setPendingVariableNames((prev) => {
        return [...prev, ...variableName]
          .filter((variable, index, self) => self.findIndex((v) => v === variable) === index)
          .sort();
      });
    },
    [pendingVariableNames]
  );

  useEffect(() => {
    _fetchVariable(pendingVariableNames.filter((v) => !(v in variableOptions)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingVariableNames, _fetchVariable]);

  const _fetchStatus = useCallback(
    (prefix: string[]) => {
      const context = 'status';
      updateStatus(context, 'LOADING');
      Promise.all(
        prefix.map((p) => globalService.fetchStatusOptions({ limit: 100, offset: 0, prefix: p }))
      )
        .then((res) => {
          updateStatus(context, 'IDLE');
          setStatusOptions((prev) => {
            let newStatus: { [prefix: string]: Status[] } = {};
            res.forEach((r, index) => {
              newStatus[prefix[index]] = r.data;
            });
            return {
              ...prev,
              ...newStatus
            };
          });
        })
        .catch((err) => {
          updateStatus(context, 'ERROR', err);
          setTimeout(() => updateStatus(context, 'IDLE'), timeoutOnError);
        });
    },
    [globalService, updateStatus]
  );

  const fetchStatus = useCallback(
    (prefix: string[]) => {
      let needUpdate: boolean = false;
      prefix.forEach((p) => {
        if (!pendingStatusPrefix.includes(p)) needUpdate = true;
      });
      if (!needUpdate) return;
      setPendingStatusPrefix((prev) => {
        return [...prev, ...prefix]
          .filter((prefix, index, self) => self.findIndex((p) => p === prefix) === index)
          .sort();
      });
    },
    [pendingStatusPrefix]
  );

  useEffect(() => {
    _fetchStatus(pendingStatusPrefix.filter((p) => !(p in statusOptions)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingStatusPrefix, _fetchStatus]);

  const isLoading = useMemo(() => {
    return (
      componentStatus?.variable.status === 'LOADING' ||
      componentStatus?.status.status === 'LOADING' ||
      false
    );
  }, [componentStatus?.status.status, componentStatus?.variable.status]);

  return (
    <GlobalOptionsFetchingContext.Provider
      value={{
        fetchVariable,
        fetchStatus,
        statusOptions,
        variableOptions,
        isLoading
      }}
    >
      {children}
    </GlobalOptionsFetchingContext.Provider>
  );
}

export { GlobalOptionsFetchingProvider, GlobalOptionsFetchingContext };
