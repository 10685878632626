import { useTaxonomyService } from '@restworld/data-services';
import { EntityId, Occupation, Skill } from '@restworld/utility-types';
import { useCallback, useState } from 'react';

export default function useTaxonomy() {
  const taxonomyService = useTaxonomyService();
  const [skills, setSkills] = useState<Skill[]>([]);
  const [occupations, setOccupations] = useState<Occupation[]>([]);
  const [isOccupationLoading, setIsOccupationLoading] = useState<boolean>(false);
  const [isSkillLoading, setIsSkillLoading] = useState<boolean>(false);

  const indexOccupations = useCallback(
    (domainInterestId?: EntityId) => {
      setIsOccupationLoading(true);
      taxonomyService
        .indexOccupations(domainInterestId)
        .then((res) => {
          setIsOccupationLoading(false);
          setOccupations(res.data.sort((x, y) => x.label_it.label.localeCompare(y.label_it.label)));
        })
        .catch((err) => {
          setIsOccupationLoading(false);
        });
    },
    [taxonomyService]
  );

  const indexSkills = useCallback(
    (occupationId?: number) => {
      setIsSkillLoading(true);
      taxonomyService
        .indexSkills(occupationId)
        .then((res) => {
          setIsSkillLoading(false);
          setSkills(res.data.sort((x, y) => x.label_it.label.localeCompare(y.label_it.label)));
        })
        .catch((err) => {
          setIsSkillLoading(false);
        });
    },
    [taxonomyService]
  );

  return {
    skills,
    occupations,
    indexSkills,
    indexOccupations,
    isOccupationLoading,
    isSkillLoading
  };
}
