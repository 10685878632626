import { MoreVert, Save } from '@mui/icons-material';
import { IconButton, TextField, useTheme } from '@mui/material';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  avatarUrl: string;
  content?: string;
  isEditing?: boolean;
  handleSaveMessage: (message: string) => void;
  handleOpenActionMenu: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
export default function ChatItem({
  avatarUrl,
  content,
  isEditing,
  handleSaveMessage,
  handleOpenActionMenu
}: Props) {
  const theme = useTheme();
  const [internalMessage, setInternalMessage] = useState<string>(content || '');

  useEffect(() => {
    setInternalMessage(content || '');
  }, [content]);

  const handleSubmitMessage = () => {
    handleSaveMessage(internalMessage);
  };

  return (
    <Box>
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{ p: 3, '&:hover': { bgcolor: 'grey.200' } }}
      >
        <Grid item xs={1.5}>
          <Avatar src={avatarUrl} sx={{ width: 40, height: 40 }} />
        </Grid>
        <Grid item xs={9.5}>
          {!isEditing ? (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              {content}
            </Typography>
          ) : (
            <form onSubmit={handleSubmitMessage}>
              <TextField
                multiline
                sx={{ width: '100%' }}
                value={internalMessage}
                onChange={(e) => setInternalMessage(e.target.value)}
                InputProps={{
                  sx: { fontSize: theme.typography.body2.fontSize },
                  endAdornment: (
                    <IconButton onClick={handleSubmitMessage}>
                      <Save />
                    </IconButton>
                  )
                }}
              />
            </form>
          )}
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleOpenActionMenu}>
            <MoreVert />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
}
