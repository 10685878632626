import { useCallback, useEffect, useState } from 'react';
import { Socket } from 'phoenix';
import { HOST_ENDPOINT_BASE_URL } from 'config';

const host = HOST_ENDPOINT_BASE_URL.split('//')[1];
const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';

const socket = new Socket(`${protocol}//${host}socket`, {});
const channelTopic = 'cv_parsing';

export default function useSocketPresence() {
  const [workerId, setWorkerId] = useState<string | null>(null);

  socket.connect();

  const initWorker = useCallback((workerId: string) => {
    setWorkerId(workerId);
  }, []);

  const openConnection = useCallback(() => {
    if (!workerId) return;
    let channel = socket.channel(channelTopic, { worker_id: workerId });
    channel.join();
  }, [workerId]);

  useEffect(() => {
    openConnection();
  }, [openConnection]);

  return { initWorker };
}
