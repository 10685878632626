import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import MainNavbar from '../main/MainNavbar';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
// const APP_BAR_MOBILE = 0;
// const APP_BAR_DESKTOP = 0;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

interface MainStyleProps extends BoxProps {
  isAuthenticated?: boolean;
}
const MainStyle = styled(Box)<MainStyleProps & { isAuthenticated: boolean }>(
  ({ theme, isAuthenticated }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: (isAuthenticated ? APP_BAR_MOBILE : 0) + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: (isAuthenticated ? APP_BAR_DESKTOP : 0) + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  })
);

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const { collapseClick } = useCollapseDrawer();

  return (
    <RootStyle>
      {isAuthenticated && <MainNavbar />}
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
        isAuthenticated={isAuthenticated}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
