import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import DelayAction from 'components/Snackbar/DelayActionVariant';
import { Props as CustomSnackbarComponentProps } from 'components/Snackbar/DelayActionVariant';

declare module 'notistack' {
  interface VariantOverrides {
    delayAction: CustomSnackbarComponentProps;
  }
}

export function CustomSnackbarProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <style>
        {`.snackbar-custom {
          z-index: 3000
        }`}
      </style>
      <SnackbarProvider
        classes={{ containerRoot: 'snackbar-custom' }}
        Components={{
          delayAction: DelayAction
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
