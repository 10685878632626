import { EntityType, VariableOptions } from '@restworld/utility-types';
import {
  differenceInBusinessDays,
  formatDistanceStrict,
  formatISO,
  formatRelative
} from 'date-fns';

import { OptionsWithTZ, format as formatTz } from 'date-fns-tz';

import it from 'date-fns/locale/it';
import { activeJobApplicationStatus, inactiveJobApplicationStatus } from 'constants/common';

export function removeUndefined(obj: any) {
  for (let k in obj) if (obj[k] === undefined || obj[k] === null) delete obj[k];
  return obj;
}

export function isObject(obj: any) {
  if (obj === null || typeof obj !== 'object') return false;
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function serializeDate(date: Date) {
  // return format(date, 'yyyy-MM-ddTHH:mm:ss.SSSxxx', { locale: it });
  return formatISO(date);
}

export function formatTime({
  dateTime,
  noTime,
  diffDays
}: {
  dateTime: string;
  noTime?: boolean;
  diffDays?: boolean;
}) {
  let date = new Date(dateTime);

  const now = new Date();
  if (diffDays) return formatRelative(date, now, { locale: it });
  if (noTime) return date.toLocaleDateString();
  return date.toLocaleString([], { timeStyle: 'short', dateStyle: 'long' });
}

export type FilterVariableOptionsType = (
  variables: VariableOptions,
  entity: EntityType
) => VariableOptions;

export const filterVariableOptions: FilterVariableOptionsType = (variables, entity) => {
  if (entity === 'job_position') {
    let res: VariableOptions = {};
    Object.keys(variables).forEach((variableName) => {
      switch (variableName) {
        case 'sex':
          res[variableName] = variables[variableName].filter((v) => v.key !== 'unspecified');
          break;
        case 'skills':
          res[variableName] = variables[variableName].filter(
            (value, index, self) => self.findIndex((v) => v.label === value.label) === index
          ); // Uniqueness of labels
          break;
        case 'experience':
          res[variableName] = variables[variableName].filter(
            (v) => v.parent_key === 'job_position'
          );
          break;
        default:
          res[variableName] = variables[variableName];
      }
    });
    return res;
  }
  if (entity === 'worker') {
    let res: VariableOptions = {};
    Object.keys(variables).forEach((variableName) => {
      switch (variableName) {
        case 'experience':
          res[variableName] = variables[variableName].filter((v) => v.parent_key === 'worker');
          break;
        default:
          res[variableName] = variables[variableName];
      }
    });
    return res;
  }
  return variables;
};

export const formatWhatsAppPhoneNumber = (phoneNumber: string) => {
  let phone;
  if (!phoneNumber.startsWith('+')) phone = '+39' + phoneNumber;
  else phone = phoneNumber;

  return phone.replaceAll(' ', '').replaceAll('-', '').replaceAll('_', '');
};

export const computeAge = (dob: Date) => {
  return formatDistanceStrict(new Date(), dob, {
    roundingMethod: 'floor',
    unit: 'year',
    locale: it
  });
};

export const getDateFromSeconds = (seconds: number) => {
  var date = new Date(0, 0, 1); // Epoch
  date.setFullYear(0);
  date.setSeconds(seconds);
  return date;
};

export const getDueDateColor = (dueDate: Date, defaultColor: string = 'default') => {
  const daysDiff = differenceInBusinessDays(dueDate, new Date());

  if (daysDiff < 0) return 'error';
  if (daysDiff < 2) return 'warning';
  return defaultColor;
};

export const getWorkerJpLabelColor = (statusKey: string) => {
  if (activeJobApplicationStatus.includes(statusKey)) return 'success';
  if (inactiveJobApplicationStatus.includes(statusKey)) return 'error';
  return 'inherit';
};

export const getLabelColor = (
  sentiment?: 'positive' | 'negative' | 'neutral',
  defaultColor: 'default' | 'inherit' = 'default',
  statusKey?: string
) => {
  if (sentiment === 'positive') return 'success';
  if (sentiment === 'negative' && statusKey === 'worker_jp_not_selected') return 'warning';
  if (sentiment === 'negative') return 'error';
  return defaultColor;
};

export const formatDate = (date: string, formatString: string, options?: OptionsWithTZ) => {
  let d = date;
  if (!d.endsWith('Z')) d += 'Z';
  return formatTz(new Date(d), formatString, { timeZone: 'Europe/Rome', locale: it, ...options });
};
