import { Box, BoxProps, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks';
import { Search as SearchIcon } from '@mui/icons-material';

export type SearchBoxProps = UseSearchBoxProps & {
  placeholder?: string;
};

interface Props extends BoxProps {
  searchBoxProps?: SearchBoxProps;
}

const AlgoliaSearchBox = forwardRef<HTMLDivElement, Props>(
  ({ searchBoxProps, ...rest }: Props, ref) => {
    const { query, refine, isSearchStalled } = useSearchBox(searchBoxProps);
    const [inputValue, setInputValue] = useState(query);
    const inputRef = useRef<HTMLInputElement>(null);

    // Track when the value coming from the React state changes to synchronize
    // it with InstantSearch.
    useEffect(() => {
      if (query !== inputValue) {
        refine(inputValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, refine]);

    // Track when the InstantSearch query changes to synchronize it with
    // the React state.
    // useEffect(() => {
    //   // Bypass the state update if the input is focused to avoid concurrent
    //   // updates when typing.
    //   if (document.activeElement !== inputRef.current && query !== inputValue) {
    //     setInputValue(query);
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [query]);

    return (
      <Box {...rest} ref={ref}>
        <TextField
          autoFocus
          ref={inputRef}
          placeholder={searchBoxProps?.placeholder || 'Cerca...'}
          variant="outlined"
          fullWidth
          InputProps={{
            ...(isSearchStalled
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CircularProgress thickness={2} size={20} />
                    </InputAdornment>
                  )
                }
              : {}),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          value={inputValue}
          onChange={(event) => setInputValue(event.currentTarget.value)}
        />
      </Box>
    );
  }
);

export default AlgoliaSearchBox;
