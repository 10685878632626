import { FastForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import { useCallback, useState } from 'react';

interface Props {
  onConfirm: () => void;
  isSkippingLoading: boolean;
}

export default function SkipCv({ onConfirm, isSkippingLoading }: Props) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const handleSkipClicked = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  return (
    <>
      <Dialog open={isConfirmModalOpen} onClose={handleModalClose}>
        <DialogTitle>Sicur* di voler saltare questo CV?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Salta questo CV solo se non è significativo ai fini del parsing o se è tutto corretto
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="inherit" variant="contained">
            Annulla
          </Button>
          <LoadingButton onClick={onConfirm} variant="contained" loading={isSkippingLoading}>
            Salta
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Tooltip title="Salta il CV">
        <IconButton onClick={handleSkipClicked}>
          <FastForward />
        </IconButton>
      </Tooltip>
    </>
  );
}
