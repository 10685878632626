import { Grid, TextField, Tooltip } from '@mui/material';
import { StyledInputLabel } from 'pages/onboarding/JobPositionForm/components';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { IRestaurantFormValues } from '../RestaurantForm.types';
import { InfoOutlined } from '@mui/icons-material';

interface ITextFieldFormComponentProps {
  formId: keyof IRestaurantFormValues;
  formLabel: string;
  required?: boolean;
  placeholder?: string;
  inputType?: React.HTMLInputTypeAttribute;
  isContainer?: boolean;
  disabled?: boolean;
  labelInfoText?: string;
  multiline?: boolean;
}

const TextFieldFormComponent: React.FunctionComponent<ITextFieldFormComponentProps> = ({
  formId,
  formLabel,
  required,
  placeholder,
  inputType,
  isContainer = true,
  disabled,
  labelInfoText,
  multiline
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<IRestaurantFormValues>();
  return (
    <Grid container={isContainer} item key={formId}>
      <StyledInputLabel
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: 0.25
        }}
        htmlFor={formId}
        required={required}
      >
        <div>{formLabel}</div>
        {!!labelInfoText && (
          <Tooltip title={labelInfoText} arrow>
            <InfoOutlined />
          </Tooltip>
        )}
      </StyledInputLabel>
      <TextField
        id={formId}
        {...register(formId, { required: required ? 'Questo campo è obbligatorio!' : false })}
        fullWidth
        variant="outlined"
        error={!!errors[formId]}
        helperText={!!errors[formId] ? (errors[formId]?.message as string) : ''}
        placeholder={placeholder}
        type={inputType}
        disabled={disabled}
        rows={multiline ? 3 : 1}
        multiline={multiline}
        maxRows={4}
      />
    </Grid>
  );
};

export default TextFieldFormComponent;
