import { Typography, Grid, IconButton, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DisplayItem } from './DisplayItem';

// interface Props {}

export default function PastExperiences() {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'past_experiences'
  });

  return (
    <div>
      {(!fields || fields.length === 0) && (
        <Typography sx={{ my: 2 }}>Nessuna esperienza passata</Typography>
      )}
      {!!fields && fields.length > 0 && (
        <Grid container>
          {fields.map((field, index) => (
            <Grid item key={field.key} xs={6}>
              <DisplayItem index={index} deletePastExp={() => remove(index)} />
            </Grid>
          ))}
        </Grid>
      )}
      <Stack direction="row" justifyContent="center" alignItems="center">
        <IconButton onClick={() => append({})}>
          <Add />
        </IconButton>
      </Stack>
    </div>
  );
}
