// material
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';
// import { url } from 'inspector';

// ----------------------------------------------------------------------

type ColorType = 'violet';

interface Props extends BoxProps {
  color?: ColorType;
}

const logoPalette = {
  violet: {
    light: '#6e3dc2',
    main: '#4a2983',
    dark: '#2c184e'
  }
};

const Logo = forwardRef<HTMLDivElement, Props>(({ sx, color, ...rest }, ref) => {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  let PRIMARY_MAIN;
  let PRIMARY_DARK;
  switch (color) {
    case 'violet':
      PRIMARY_MAIN = logoPalette.violet.main;
      PRIMARY_DARK = logoPalette.violet.dark;
      break;
    default:
      PRIMARY_MAIN = logoPalette.violet.main;
      PRIMARY_DARK = logoPalette.violet.dark;
  }

  return (
    <Box sx={{ width: 40, height: 40, ...sx }} {...rest} ref={ref}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 512 436"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinejoin: 'round',
          strokeMiterlimit: 2
        }}
      >
        <g transform="matrix(1,0,0,1,-6.88767e-05,9.64195e-05)">
          <g id="Group">
            <path
              d="M504.806,126.448C494.641,115.402 477.388,114.675 466.321,124.84C464.5,126.512 421.31,166.577 384.988,228.195C365.898,260.601 351.13,292.467 340.703,317.577C330.496,304.779 319.45,291.506 307.699,277.896C297.257,265.825 286.019,253.177 273.819,241.148C305.322,207.631 326.987,160.349 329.919,117.437C332.659,77.436 324.183,47.535 304.019,26.089C282.403,3.1 250.68,-5.692 223.199,3.725C179.902,18.537 145.098,76.88 138.549,145.585C137.738,154.04 137.268,163.286 137.118,173.195C79.159,167.031 21.243,191.259 4.76,228.712C-5.491,251.979 1.037,276.849 22.204,295.212C47.608,317.256 90.611,322.523 143.261,310.024C144.996,309.597 146.748,309.169 148.506,308.72C154.392,347.397 160.406,375.883 161.133,379.306C164.257,393.968 178.748,403.364 193.453,400.261C200.578,398.738 206.678,394.545 210.642,388.445C214.605,382.346 215.93,375.049 214.407,367.94C214.108,366.466 207.09,333.029 200.947,290.373C211.475,285.662 221.41,280.572 230.613,275.134C243.197,287.013 255.226,300.516 266.485,313.533C305.953,359.186 326.073,388.638 326.287,388.937L357.918,435.508L374.808,381.785C375,381.165 394.95,318.602 431.934,255.868C463.672,201.995 501.665,166.379 503.208,164.926C514.265,154.762 514.97,137.493 504.806,126.447L504.806,126.448ZM58.29,256.899C54.653,253.176 54.311,247.332 55.849,243.716C58.781,236.869 77.893,226.677 118.626,226.677C123.209,226.677 127.829,226.955 132.391,227.468C134.639,227.724 136.861,228.023 139.067,228.365C139.73,236.799 140.461,245.661 141.428,254.693C97.335,266.983 66.574,265.333 58.29,256.899ZM231.591,206.412C218.729,197.978 205.52,191.238 191.568,185.95C191.397,172.81 191.803,160.995 192.786,150.739C197.689,99.158 219.434,63.307 237.413,55.797C237.584,55.712 256.999,48.757 269.562,62.132C282.787,76.196 281.142,96.162 278.253,111.977C273.114,139.945 261.027,168.04 245.409,189.811C241.055,195.885 236.425,201.461 231.591,206.412Z"
              style={{
                fill: PRIMARY_MAIN,
                fillRule: 'nonzero'
              }}
            />
            <path
              id="Shadow-1"
              d="M320.682,381.048L300.647,354.739L340.694,317.543C328.188,338.901 321.961,359.328 320.682,381.048Z"
              fill="url(#_Linear1)"
            />
            <path
              id="Shadow-2"
              d="M139.06,228.303C139.321,234.569 140.999,250.494 141.429,254.522C163.689,260.807 183.196,273.385 200.937,290.372C199.433,278.601 196.779,253.964 195.939,244.515C177.413,235.045 164.501,230.925 139.06,228.303Z"
              fill="url(#_Linear2)"
            />
            <path
              id="Shadow-3"
              d="M231.505,206.451C239.479,197.62 246.608,188.365 252.87,178.676L295.601,213.395C289.216,223.474 281.575,232.742 273.701,241.17C260.031,228.493 246.407,216.748 231.505,206.451Z"
              fill="url(#_Linear3)"
            />
            <path
              id="Shadow-4"
              d="M139.364,138.463C157.287,141.561 175.068,145.355 192.76,149.588C191.538,161.629 191.624,173.992 191.979,186.153C173.844,180.057 155.53,175.561 136.963,173.334C137.271,161.921 137.583,150.292 139.364,138.463Z"
              fill="url(#_Linear4)"
            />
            <path
              id="Shadow-5"
              d="M110.793,261.461C121.151,259.688 131.408,257.581 141.414,254.612C143.511,272.474 145.914,290.44 148.419,308.441C139.121,310.948 129.71,312.836 120.2,314.171C118.02,296.625 115.048,279.059 110.793,261.461Z"
              fill="url(#_Linear5)"
            />
            <path
              id="Shadow-6"
              d="M200.935,290.363C211.258,285.983 221.02,280.751 230.619,275.13C218.996,263.249 208.289,252.737 195.948,244.606C197.284,260.612 198.938,274.434 200.935,290.363Z"
              fill={PRIMARY_DARK}
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="_Linear1"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(16.4723,12.3834,-12.3834,16.4723,320.288,345.289)"
          >
            <stop offset="0" stopColor={PRIMARY_MAIN} stopOpacity={1} />
            <stop offset="1" stopColor={PRIMARY_DARK} stopOpacity={1} />
          </linearGradient>
          <linearGradient
            id="_Linear2"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-10.9294,25.0126,-25.0126,-10.9294,169.461,234.058)"
          >
            <stop offset="0" stopColor={PRIMARY_DARK} stopOpacity={1} />
            <stop offset="1" stopColor={PRIMARY_MAIN} stopOpacity={1} />
          </linearGradient>
          <linearGradient
            id="_Linear3"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(22.9017,-23.7318,23.7318,22.9017,251.13,221.203)"
          >
            <stop offset="0" stopColor={PRIMARY_DARK} stopOpacity={1} />
            <stop offset="1" stopColor={PRIMARY_MAIN} stopOpacity={1} />
          </linearGradient>
          <linearGradient
            id="_Linear4"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(3.65181,-33.727,33.727,3.65181,161.826,177.806)"
          >
            <stop offset="0" stopColor={PRIMARY_DARK} stopOpacity={1} />
            <stop offset="1" stopColor={PRIMARY_MAIN} stopOpacity={1} />
          </linearGradient>
          <linearGradient
            id="_Linear5"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(19.4373,-5.08169,5.08169,19.4373,124.128,283.592)"
          >
            <stop offset="0" stopColor={PRIMARY_MAIN} stopOpacity={1} />
            <stop offset="1" stopColor={PRIMARY_DARK} stopOpacity={1} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
});

export default Logo;
