import { Icon } from '@iconify/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import peopleFill from '@iconify/icons-eva/people-fill';
import taxonomyIcon from '@iconify/icons-eva/globe-fill';
import trendingUpIcon from '@iconify/icons-eva/trending-up-fill';
import feedbackIcon from '@iconify/icons-ic/outline-feedback';
import { Link as RouterLink } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import { Avatar, Button, Box, Divider, MenuItem, Typography } from '@mui/material';

import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';
import { RWMemberRole } from '@restworld/utility-types';

const MENU_OPTIONS: any[] = [
  { label: 'Metriche', icon: trendingUpIcon, linkTo: '/metrics' },
  { label: 'Tassonomia', icon: taxonomyIcon, linkTo: '/taxonomy', isAdmin: true }
];

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { logout, user } = useAuth();

  const isAdmin = useMemo(
    () => user?.data.roles?.map((r: RWMemberRole) => r.key).includes('admin'),
    [user?.data]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const isOutsourceSalesPerson = useMemo(() => {
    const roles = user?.data?.roles?.map((role: any) => role?.key);
    return roles?.includes('outsourcing_sales');
  }, [user]);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar
          alt="My Avatar"
          src={user?.data?.icon_url || '/static/mock-images/avatars/avatar_default.jpg'}
        />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.data?.name} {user?.data?.surname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.data?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {!isOutsourceSalesPerson
          ? MENU_OPTIONS.filter(
              (option) => (option.isAdmin === true && isAdmin) || !option.isAdmin
            ).map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24
                  }}
                />

                {option.label}
              </MenuItem>
            ))
          : []}
        {!isOutsourceSalesPerson && (
          <MenuItem
            onClick={() => {
              window.open(
                'https://restworld.atlassian.net/servicedesk/customer/portal/8',
                '_blank'
              );
              handleClose();
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={feedbackIcon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            Feedback
          </MenuItem>
        )}
        <MenuItem
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
          to="/team-mates"
          component={RouterLink}
          onClick={handleClose}
        >
          <Box
            component={Icon}
            icon={peopleFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />
          Team
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => logout()}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
