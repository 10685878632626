import { useEffect } from 'react';
import { Stack, useTheme, Link, Typography, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useQueryParams } from './RestaurantForm.utils';
import { IRestaurantFormCategory } from './RestaurantForm.types';

interface IRestaurantGlossariesProps {
  formCategories: IRestaurantFormCategory[];
}

const RestaurantGlossaries: React.FunctionComponent<IRestaurantGlossariesProps> = ({
  formCategories
}) => {
  const theme = useTheme();
  const getQuery = useQueryParams();
  const activeCategoryId = getQuery('activeCategoryId');
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const activeCategoryElement = document.getElementById(activeCategoryId),
        offset = 100;
      window.scrollTo({
        behavior: 'smooth',
        top:
          (activeCategoryElement ? activeCategoryElement.getBoundingClientRect().top : 0) -
          document.body.getBoundingClientRect().top -
          offset
      });
    }
  }, [activeCategoryId]);

  return (
    <Box sx={{ px: 3 }}>
      <Typography variant="h5">Indice</Typography>
      <Stack gap={0.5} sx={{ mt: 2 }}>
        {formCategories.map((category) => {
          return (
            <Link
              key={category.id}
              sx={{
                cursor: 'pointer',
                color:
                  category.id === activeCategoryId
                    ? theme.palette.primary.main
                    : theme.palette.grey[600]
              }}
              underline="hover"
              onClick={() => {
                setSearchParams((params) => ({
                  ...Object.fromEntries(params.entries()),
                  activeCategoryId: category.id
                }));
              }}
            >
              {category.label}
            </Link>
          );
        })}
      </Stack>
    </Box>
  );
};

export default RestaurantGlossaries;
