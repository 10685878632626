import { Typography, TypographyProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export default function PopupLabel({
  labels,
  label,
  visibleLength = 30,
  ...props
}: {
  labels?: Array<string>;
  label?: string;
  visibleLength?: number;
} & TypographyProps) {
  const labelStr = labels?.join(', ') || label;
  if (!labelStr) return null;
  if (!!label && !!labels) return null;

  const truncate = (str: string, max: number) => {
    var len = str.length;
    if (len > max) {
      return str.slice(0, max - 1) + '...';
    }
    return str;
  };

  const content = truncate(labelStr, visibleLength);
  if (content.length <= visibleLength) return <Typography {...props}>{content}</Typography>;

  return (
    <Tooltip
      title={
        <>
          {labels?.map((l, idx) => (
            <div key={idx}>{l}</div>
          ))}
          {label}
        </>
      }
    >
      <Typography {...props}>{truncate(labelStr, visibleLength)}</Typography>
    </Tooltip>
  );
}
