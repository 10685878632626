import { Box, Chip, CircularProgress, FormHelperText, Select } from '@mui/material';
import { Grid } from '@mui/material';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem } from '@mui/material';

import { StyledInputLabel } from 'pages/onboarding/JobPositionForm/components';

import { MenuProps } from '../RestaurantForm.utils';
import { IRestaurantFormValues, ISelectFormInputProps } from '../RestaurantForm.types';

interface IMultiSelectFormComponentProps extends ISelectFormInputProps {}

const MultiSelectFormComponent: React.FunctionComponent<IMultiSelectFormComponentProps> = ({
  options,
  formLabel,
  formId,
  loading,
  required,
  renderMenuItems,
  placeholder
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext<IRestaurantFormValues>();
  return (
    <Grid item container key={formId}>
      <Grid item container gap={2}>
        <StyledInputLabel htmlFor={formId}>{formLabel}</StyledInputLabel>
        {loading && <CircularProgress size={20} />}
      </Grid>
      <Controller
        name={formId}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Select
            sx={{
              width: '100%'
            }}
            labelId={formId}
            id={formId}
            multiple
            MenuProps={MenuProps}
            error={!!errors[formId]}
            {...field}
            renderValue={() => {
              const selectedLabels = options
                ?.filter((v) => (field.value as string[]).includes(v.value))
                .map((v) => v.label);
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedLabels?.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              );
            }}
            onChange={(event) => {
              const {
                target: { value }
              } = event;
              field.onChange(typeof value === 'string' ? value.split(',') : value);
            }}
          >
            <MenuItem disabled value="">
              {loading ? 'Caricamento opzioni...' : placeholder}
            </MenuItem>
            {renderMenuItems
              ? renderMenuItems
              : options?.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
          </Select>
        )}
      />
      {!!errors[formId] && <FormHelperText error>{errors[formId]?.message}</FormHelperText>}
    </Grid>
  );
};
export default MultiSelectFormComponent;
