import { ENDPOINTS } from 'constants/api';
import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';

class ServiceGlobals extends Service {
  fetchVariableOptions(variableName: string) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.globals.variables, {
      params: { variable_name: variableName }
    });
  }

  fetchMultipleVariableOptions(variableNames: string[]) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.globals.variables, {
      params: { variable_names: variableNames }
    });
  }

  fetchStatusOptions(limit: number, offset: number, prefix: string) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.globals.status, {
      params: { limit, offset, prefix }
    });
  }

  compressImage(image: File, sizeThreshold: number) {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('size_threshold', sizeThreshold.toString());
    return dynamicAxios(this.jwt).post(ENDPOINTS.globals.compressImage, formData, {
      responseType: 'arraybuffer'
    });
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceGlobals(jwt), [jwt]);
};
export default useService;
