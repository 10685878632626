import { Stack, Typography, Grid, Box, List, ListItem, useTheme, Tooltip } from '@mui/material';
import { Configure, Index, useSearchBox, useHits } from 'react-instantsearch-hooks';
import AlgoliaSearchBox from 'components/algolia/AlgoliaSearchBox';
import { Card } from '@mui/material';
import Info from '@mui/icons-material/InfoOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const RestaurantHit = ({ hit }: { hit: any }) => {
  return (
    <Box>
      <Typography variant="body1" fontWeight="900">
        {hit?.restaurant_name} - {hit?.organization?.name}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: 'grey.600', mb: 1 }}>
        {hit.address}
      </Typography>
      {hit?.employer?.contacts?.map((contact: any) => {
        return (
          <Stack key={contact?.email}>
            <Typography variant="subtitle2">
              {contact?.name} - {contact?.email}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );
};

const Hits = () => {
  const { hits } = useHits();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const onChangeRestaurant = useCallback(
    ({ restaurantId }: { restaurantId: string }) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('restaurantId', restaurantId);
      navigate({ search: searchParams.toString() }, { replace: true });
    },
    [location.search, navigate]
  );

  return (
    <List sx={{ width: '100%' }}>
      {hits?.map((hit: any) => (
        <ListItem
          sx={{
            '&:hover': {
              bgcolor: 'grey.100',
              cursor: 'pointer'
            },
            p: 3,
            mb: 3,
            boxShadow: theme.shadows[5]
          }}
          components={{ Root: Card }}
          key={hit.objectID}
          onClick={() => {
            onChangeRestaurant({ restaurantId: hit.objectID });
          }}
        >
          <RestaurantHit hit={hit} />
        </ListItem>
      ))}
    </List>
  );
};

export function SearchRestaurant() {
  const { query } = useSearchBox();

  return (
    <Stack alignItems="center">
      <Stack sx={{ width: '50%' }}>
        <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
          <Typography variant="h5">
            Cerca il locale al quale associare la nuova posizione lavorativa
          </Typography>
          <Tooltip title="Inserisci il nome del locale per il quale creare una posizione lavorativa. Dopodichè, clicca su uno dei risultati proposti e inizia la registrazione">
            <Info fontSize="medium" sx={{ color: 'grey.500' }} />
          </Tooltip>
        </Stack>
        <Configure hitsPerPage={10} />
        <Box sx={{ mt: 2 }}>
          <AlgoliaSearchBox searchBoxProps={{ placeholder: 'Burger king' }} />
        </Box>
        <Grid container sx={{ mt: 1 }} id="hit-grid">
          {query !== '' && (
            <Index indexName="restaurants">
              <Hits />
            </Index>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
}
